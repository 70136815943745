// eslint-disable-next-line no-shadow
enum ErrorCodes {
  GENERIC_BAD_REQUEST_ERROR = 400,
  GENERIC_UNAUTHORIZED_ERROR = 401,
  GENERIC_FORBIDDEN_ERROR = 403,
  UNEXPECTED_SERVER_ERROR = 500,
  NOT_LOGGED_IN = 4001,
  NO_SESSION_USER_ID = 4002,
  INVALID_SESSION_USER = 4003,
  INVALID_PROGRAM_ID = 4004,
  NO_ACCESS_TO_DISTRICTS = 4005,
  NO_ACCESS_TO_SCHOOLS = 4006,
  NO_ACCESS_TO_CLASSES = 4007,
  NO_ACCESS_TO_STUDENTS = 4008,
  NO_ACCESS_TO_REQUESTED_DISTRICT = 4009,
  INVALID_SCHOOL = 4010,
  SCHOOL_NOT_IN_DISTRICT = 4011,
  NO_ACCESS_TO_SA_SCHOOLS = 4012,
  NO_ACCESS_TO_TA_CLASSES = 4013,
  INVALID_CLASS = 4014,
  CLASS_NOT_IN_SCHOOL = 4015,
  POST_REQUIRED = 4016,
  SAVE_SCHEDULES_FAILED = 4019,
  SAVE_SCHEDULES_FAILED_PARTIALLY = 4020,
  NO_ACCESS_TO_PERMISSIONS = 4021,
  CANNOT_UPDATE_PERMISSIONS = 4022,
  INVALID_SCHEDULER_PERMISSIONS = 4023,
  SAVE_PERMISSION_FAILED = 4024,
  MISSING_REQUIRED_PARAMS = 4025,
  BAD_SCHEDULER_DATA = 4026,
  TEST_BETWEEN_SCHOOL_START_END = 4027,
  PRE_AFTER_OTHERS = 4028,
  INTERIM_BEFORE_PRE = 4029,
  INTERIM_AFTER_INTERIM2_OR_POST = 4030,
  INTERIM2_BEFORE_PRE_OR_INTERIM = 4031,
  INTERIM2_AFTER_POST = 4032,
  POST_BEFORE_OTHERS = 4033,
  SUMMER_PRE_AFTER_POST = 4034,
  SUMMER_POST_BEFORE_PRE = 4035,
}

export default ErrorCodes;
