import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormattedMessageRollover from '../../lang/FormattedMessage';

interface BackDropProps {
  messageId: string
  defaultText?: string
}

// Todo add formatted message for text
export default ({ messageId, defaultText }: BackDropProps) => (
  <div>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
      open
    >
      <CircularProgress color="inherit" />
      <FormattedMessageRollover id={messageId} defaultMessage={defaultText ?? messageId} />
    </Backdrop>
  </div>
)
