import ErrorDetails, { ErrorResponseData } from '../interfaces/ErrorDetail'
import { isValidObject } from './shared';
import ErrorCodes from './ErrorCodes';

class ErrorDetail implements ErrorDetails {
  status: number;

  message: string;

  statusText: string;

  errorResponse: ErrorResponseData;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(ex: any) {
    // eslint-disable-next-line prefer-destructuring
    const response = ex?.response;
    this.status = response?.status || -1;
    this.message = response?.data?.message || response?.data?.error || ex.message || 'Unknown Error';
    this.statusText = response?.statusText || 'Server Error';
    this.errorResponse = { errorCode: this.status, errorMsg: 'serverError' }
    if (isValidObject<{message: unknown}>(response?.data, ['message'])
      && isValidObject<ErrorResponseData>(response?.data.message, ['errorCode', 'errorMsg'])) {
      this.errorResponse = { errorCode: response.data.message.errorCode, errorMsg: response.data.message.errorMsg }
    }
  }

  // eslint-disable-next-line max-len
  toString = (): string => `Status Code= ${this.status}, Message= ${this.message}, Status Text = ${this.statusText}, errors: ${JSON.stringify(this.errorResponse)}`

  // Todo add  4019, 4020, 4021
  static getErrorKey = (errorCode: number|string): string => {
    let code:number;
    if (typeof errorCode === 'number') {
      code = errorCode;
    } else {
      code = Number.isNaN(parseInt(errorCode, 10)) ? 0 : parseInt(errorCode, 10);
    }
    switch (code) {
      case ErrorCodes.BAD_SCHEDULER_DATA:
      case ErrorCodes.GENERIC_BAD_REQUEST_ERROR:
        return 'badRequestError';
      case ErrorCodes.GENERIC_UNAUTHORIZED_ERROR:
        return 'unauthorizedError';
      case ErrorCodes.GENERIC_FORBIDDEN_ERROR:
        return 'forbiddenError';
      case ErrorCodes.UNEXPECTED_SERVER_ERROR:
        return 'serverError';
      case ErrorCodes.NOT_LOGGED_IN:
        return 'notLoggedInError';
      case ErrorCodes.NO_SESSION_USER_ID:
        return 'noSessionUserError';
      case ErrorCodes.INVALID_SESSION_USER:
        return 'invalidSessionsUserError';
      case ErrorCodes.INVALID_PROGRAM_ID:
        return 'invalidProgramIdError';
      case ErrorCodes.NO_ACCESS_TO_REQUESTED_DISTRICT:
      case ErrorCodes.NO_ACCESS_TO_DISTRICTS:
        return 'noDistrictsAccessError';
      case ErrorCodes.NO_ACCESS_TO_SA_SCHOOLS:
      case ErrorCodes.NO_ACCESS_TO_SCHOOLS:
        return 'noSchoolsAccessError';
      case ErrorCodes.NO_ACCESS_TO_TA_CLASSES:
      case ErrorCodes.NO_ACCESS_TO_CLASSES:
        return 'noClassesAccessError';
      case ErrorCodes.NO_ACCESS_TO_STUDENTS:
        return 'noStudentsAccessError';
      case ErrorCodes.SCHOOL_NOT_IN_DISTRICT:
        return 'schoolNotInDistrictError';
      case ErrorCodes.INVALID_SCHOOL:
        return 'invalidSchoolError';
      case ErrorCodes.INVALID_CLASS:
        return 'invalidClassError';
      case ErrorCodes.CLASS_NOT_IN_SCHOOL:
        return 'classNotInSchoolError';
      case ErrorCodes.POST_REQUIRED:
        return 'postRequiredError';
      case ErrorCodes.CANNOT_UPDATE_PERMISSIONS:
        return 'permissionsUpdateError';
      case ErrorCodes.INVALID_SCHEDULER_PERMISSIONS:
        return 'invalidPermissionsInPostRequestError';
      case ErrorCodes.SAVE_PERMISSION_FAILED:
        return 'permissionSaveFailedError';
      case ErrorCodes.MISSING_REQUIRED_PARAMS:
        return 'missingRequiredParamsError';

      case ErrorCodes.TEST_BETWEEN_SCHOOL_START_END:
        return 'testShouldBeBetweenSchoolStartAndEndError';
      case ErrorCodes.PRE_AFTER_OTHERS:
        return 'preAfterOtherError';
      case ErrorCodes.INTERIM_BEFORE_PRE:
        return 'interimBeforePreTestError'
      case ErrorCodes.INTERIM_AFTER_INTERIM2_OR_POST:
        return 'interimAfterInterim2OrPostError';
      case ErrorCodes.INTERIM2_BEFORE_PRE_OR_INTERIM:
        return 'interim2BeforePreOrInterimError';
      case ErrorCodes.INTERIM2_AFTER_POST:
        return 'interim2AfterPostError';
      case ErrorCodes.POST_BEFORE_OTHERS:
        return 'postBeforeOthersError';
      case ErrorCodes.SUMMER_PRE_AFTER_POST:
        return 'summerPreAfterPost';
      case ErrorCodes.SUMMER_POST_BEFORE_PRE:
        return 'summerPostAfterPre';
      default:
        return 'unknownError'
    }
  }
}

export default ErrorDetail;
