import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import { useAppContext } from '../context/AppContext';

type PaginationProps = {
  justifyContent: string
}

export const TableRowPagination = styled.tr<PaginationProps>(
  (props) => {
    const { programId } = useAppContext();
    return ({
      position: 'absolute',
      width: programId === '2' ? '1021px' : '98%',
      overflowX: 'hidden',
      minWidth: '23.5rem',
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      justifyContent: props.justifyContent || 'flex-end',
    })
  },
)

export const TableDataPaginationSpacer = styled.td`
  border: 0;
`
export const SVGNameButton = styled(IconButton)`
  text-align: start;
  align-items: flex-start;
  line-height: 1.7;
`
