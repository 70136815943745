import { useEffect, useState } from 'react';
import { IntlShape } from 'react-intl';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';

const useRolloverIntl = (
  intl: IntlShape,
  id: string,
  values: Record<string, PrimitiveType | FormatXMLElementFn<string, string>> | undefined,
  defaultMessage: string,
) => {
  const message = intl.formatMessage({ id, defaultMessage }, values);
  const rollover = intl.formatMessage({ id: `${id}.rollover`, defaultMessage: ' ' }, values);

  const [text, setText] = useState(message);

  useEffect(() => {
    setText(message);
  }, [message]);

  if (rollover === ' ') {
    return { text, events: {} };
  }

  return {
    text,
    events: {
      onMouseEnter: () => {
        setText(rollover);
      },
      onMouseLeave: () => {
        setText(message);
      },
    },
  };
};

export default useRolloverIntl;
