import React from 'react';
import styled from '@emotion/styled';

const OuterTable = styled.table`
  border: 2px solid #CC0000;
  text-align: center;
  margin: auto;
`
const StyledTable = styled.table`
  width: 424px;
  align-items: center;
  background-color: #fdf9ce;
  border-spacing: 10px;
`
const StyledFontDiv = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  color: #000000;
`
export default () => (

  <OuterTable>
    <tr>
      <td>
        <StyledTable>
          <tr>
            <td>
              <div>
                <img src="/assets/images/teacher_v2/logos/achieve3000-tagline.png" alt="achieve 3000 tagline" width="300" />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <StyledFontDiv>

                <b>
                  <p>Sorry, that page was not found.</p>
                </b>

              </StyledFontDiv>
            </td>
          </tr>
        </StyledTable>
      </td>
    </tr>
  </OuterTable>
)
