import * as React from 'react';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { visuallyHidden } from '@mui/utils';
import {
  MenuItem, Select, Stack,
} from '@mui/material';
import { useIntl } from 'react-intl';
import FormControl from '@mui/material/FormControl/FormControl';
import BootstrapDialog from './BootStrapDialog';
import FormattedMessageRollover from '../../lang/FormattedMessage';
import { useAppContext } from '../../context/AppContext';
import BackdropLoader from '../shared/BackdropLoader';
import ErrorDetail from '../../util/ErrorDetail';
// eslint-disable-next-line import/no-named-as-default
import Program from '../../enum/Program';
import AccessibleTooltip from '../AccessibleTooltip';

interface LocatorTestLevelPopupProps {
  studentId: number;
  studentName: string;
  grade: string;
  handleAlert?: () => void;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const ErrorSpanDiv = styled.div`
  color: red;
  display: block;
  padding-top: 0.25rem;
  padding-left: 0.875rem;
`
const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const intl = useIntl();

  return (
    <Stack>
      <DialogTitle sx={{ m: 0, p: 2, marginRight: '2rem' }} {...other}>
        {children}
      </DialogTitle>
      {onClose ? (
        <AccessibleTooltip title={intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            id="levelsetDialogCloseBtn"
            disableRipple
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              '&:focus': {
                outline: '2px solid black',
                outlineOffset: '-3px',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </AccessibleTooltip>
      ) : null}
    </Stack>
  );
};

export default function LocatorTestLevelPopup({
  studentId, studentName, grade, handleAlert,
}: LocatorTestLevelPopupProps) {
  const {
    languageId, schoolYearEnd,
    httpClient: { getLocatorTestLevels, getUserLocatorTestLevel, saveUserLocatorTestLevel },
  } = useAppContext();

  const [open, setOpen] = React.useState(false);
  const [isSavingLevel, setSavingLevel] = React.useState(false);
  const [error, setError] = React.useState('');
  const [selected, setSelectedValue] = React.useState('');
  const handleClickOpen = () => {
    setOpen(true);
    setTimeout(() => {
      const levelsetCloseBtn = document.querySelector('#levelsetDialogCloseBtn');
      if (levelsetCloseBtn) {
        (levelsetCloseBtn as HTMLElement).focus();
      }
    }, 50);
  };
  const handleClose = () => {
    setError('');
    setOpen(false);
  };
  const handleSave = async () => {
    setSavingLevel(true);
    const res = await saveUserLocatorTestLevel({
      userId: studentId,
      productId: Number(Program.LanguageArt),
      languageId: Number(languageId),
      levelExternalId: selected,
    });
    setSavingLevel(false);
    if (res instanceof ErrorDetail) {
      setError('saveLevelFailure')
    } else {
      handleClose();
      handleAlert?.();
    }
  }
  const getAcademicYearId = () => {
    const academicYears: {[key: number]: number} = {
      2022: 39,
      2023: 41,
      2024: 43,
      2025: 45,
    }
    return academicYears[schoolYearEnd];
  }

  const { data: userLevel } = getUserLocatorTestLevel({
    userId: studentId,
    productId: Number(Program.LanguageArt),
    academicYearId: getAcademicYearId(),
    languageId: Number(languageId),
    grade,
    isEnabled: open,
  });
  const { data: locatorTestLevels } = getLocatorTestLevels({
    productId: Number(Program.LanguageArt),
    languageId: Number(languageId),
    academicYearId: getAcademicYearId(),
    isEnabled: open,
  });

  React.useEffect(() => {
    if (open) {
      setSelectedValue(userLevel?.levelExternalId ?? '');
    }
  }, [userLevel, open])

  const dialogCloseHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape' && document.body.getAttribute('oneOrMoreTooltip') !== 'true') {
      setError('');
      setOpen(false);
    }
  }
  return (
    <div style={{
      marginTop: '0.2rem', marginBottom: '0.2rem',
    }}
    >
      <Button variant="outlined" disableRipple onClick={handleClickOpen} style={{ textTransform: 'none' }} data-testid="studentLevelLink">
        { ` ${studentName}` }
      </Button>
      {isSavingLevel && <BackdropLoader messageId="saving" /> }
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="locatortest-dialog-title"
        open={open}
        disableEscapeKeyDown
        onKeyDown={dialogCloseHandler}
      >
        <BootstrapDialogTitle id="locatortest-dialog-title" onClose={handleClose}>
          <FormattedMessageRollover id="levelSetInfo" defaultMessage="LevelSet Information" />
          : &nbsp;
          <FormattedMessageRollover id="locatorTestLevel" defaultMessage="Locator Test Level" />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div style={{ fontWeight: 'bold' }} id="studentLabel">
            <FormattedMessageRollover id="student" defaultMessage="Student" />
            <span aria-hidden="true">: &nbsp;</span>
            { ` ${studentName}` }
          </div>
          <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
            <div id="levelTitle">
              <FormattedMessageRollover id="Level" defaultMessage="Level" />
              <span aria-hidden="true">: &nbsp;</span>
            </div>
            <FormControl size="small">
              <span id="selectedLevel" style={visuallyHidden} aria-hidden="true">selected</span>
              <Select
                sx={{ minWidth: 300 }}
                value={selected}
                displayEmpty
                labelId="studentLabel levelTitle"
                id="select-level"
                onChange={(event) => {
                  setSelectedValue(event.target.value as string);
                }}
                data-testid="selectLevel"
              >
                { locatorTestLevels
               && locatorTestLevels.map((locatorTestLevel) => (
                 <MenuItem
                   value={locatorTestLevel.levelExternalId}
                   key={locatorTestLevel.levelExternalId}
                   aria-describedby={selected === locatorTestLevel.levelExternalId ? 'selectedLevel' : undefined}
                   data-testid="levelItem"
                   sx={{
                     '&:focus': {
                       outline: '2px solid black',
                       outlineOffset: '-2px',
                     },
                   }}
                 >
                   {locatorTestLevel.levelName}
                 </MenuItem>
               ))}
              </Select>
            </FormControl>
          </div>

        </DialogContent>
        {error && (
          <ErrorSpanDiv>
            <FormattedMessageRollover
              id={error}
              defaultMessage="Error saving"
            />
          </ErrorSpanDiv>
        ) }
        <DialogActions>
          <Button disableRipple autoFocus onClick={handleClose} data-testid="closeLevelButton">
            <FormattedMessageRollover id="cancel" defaultMessage="Cancel" />
          </Button>
          <Button disableRipple autoFocus onClick={handleSave} data-testid="saveLevelButton">
            <FormattedMessageRollover id="save" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </BootstrapDialog>

    </div>
  );
}
