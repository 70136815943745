import LevelSetApp, { LevelSetSchedulerAppFn } from './LevelSetApp';

interface ReactApps {
  LevelSetScheduler: LevelSetSchedulerAppFn;
}

export interface ReactAppsNamespace {
  __REACT_APPS_NAMESPACE: ReactApps;
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

(async function createWebappUIHandlers() {
  Object.assign<Window, ReactAppsNamespace>(window, {
    __REACT_APPS_NAMESPACE: {
      LevelSetScheduler: LevelSetApp,
    },
  });
}());
