import React, { ChangeEvent, useEffect } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TablePagination } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useAppContext } from '../context/AppContext';
import StudentComponent from './StudentComponent';
import CenteredRowMessage from './shared/CenteredRowMessage';
import Class from '../interfaces/Class';
import SearchBar from './shared/SearchBar';
import FormattedMessageRollover from '../lang/FormattedMessage';
import SchedulerTableView from './shared/SchedulerTableView';
import { SVGNameButton, TableRowPagination } from '../styled/styles';
import PaginationSpacer from './shared/PaginationSpacer';
import PaginationLabel, { PaginationActions, rowsPerPageSelectedA11y } from './shared/PaginationLabel';
import LevelSetSchedule from '../interfaces/LevelSetSchedule';
import { parseAssessmentSchedule } from '../util/shared';
import Checkbox from './shared/Checkbox';
import MultiSelectLevelEnum from '../enum/MultiSelectLevel';
import DateRange from '../interfaces/DateRange';
import TestNameHeaders from './TestNamesComponent';

interface ClassComponentProps {
  schoolId: number,
  hasSummerAccess: boolean,
  schoolSchedule: LevelSetSchedule[];
  isTeacherAdmin?: boolean,
  dateRange: DateRange,
  schoolName?: string,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ClassComponent = ({
  schoolId, hasSummerAccess, schoolSchedule, isTeacherAdmin, dateRange, schoolName,
}: ClassComponentProps) => {
  const {
    districtId, summer, user, httpClient: { getClassData, getTeacherAdminData },
  } = useAppContext();
  const [page, setPage] = React.useState(0);
  const [perPageRows, setPerPageRows] = React.useState(10);

  const {
    programId, languageId, activeMultiSelectId, schoolYearEnd,
  } = useAppContext();
  const { data: classes = [], isLoading } = getClassData({
    districtId, schoolId, programId, languageId, summer, isEnabled: !isTeacherAdmin, schoolYearEnd,
  });

  const { data: teacherClasses = [], isLoading: isTeacherLoading } = getTeacherAdminData({
    districtId, programId, languageId, isEnabled: !!isTeacherAdmin, summer, schoolYearEnd,
  }, user.id, schoolId);

  const getMapping = (mapping: Map<string, boolean>) => {
    if (mapping.size > 0) {
      mapping.clear();
    }
    if (isTeacherAdmin && user && user.classId > 0) {
      mapping.set(`class_${user.classId}`, true);
    }
    return mapping;
  }
  const mapping = new Map<string, boolean>();
  const [searchKey, setSearchKey] = React.useState('');
  const [filterMessage, setFilterMessage] = React.useState('');
  const [visited, setVisited] = React.useState(getMapping(mapping));
  const handleOpenClick = (key: string, id: number) => {
    if (!visited.has(`${key}_${id}`)) {
      setVisited(new Map(visited.set(`${key}_${id}`, true)));
    } else {
      visited.delete(`${key}_${id}`)
      setVisited(new Map(visited));
    }
  }
  const handleSearchKeyChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPage(0);
    setSearchKey(event.target.value);
  }

  const getPaginatedRows = (data: Class[]): Class[] => {
    if (data && data.length > perPageRows) {
      return data.slice(page * perPageRows, ((page * perPageRows) + perPageRows))
    }
    return data;
  }
  const getClassRows = (): Class[] => {
    let classData = classes;
    if (isTeacherAdmin) {
      classData = teacherClasses;
    }
    if (searchKey !== '' && searchKey.length > 0) {
      const res = classData?.filter((x) => x.class_name.toLowerCase().includes(searchKey.toLowerCase()));
      return res === undefined ? [] : res;
    }
    return classData;
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerPageRows(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setVisited(getMapping(mapping));
  }, [languageId]);

  useEffect(() => {
    const length = getPaginatedRows(getClassRows())?.length;
    setFilterMessage(searchKey && length ? `${length} result found` : '');
    const timer = setTimeout(() => setFilterMessage(''), 300);
    return () => clearTimeout(timer);
  }, [searchKey]);

  const multiSelectLevelId = `${schoolId}_${MultiSelectLevelEnum.ClassPrefix}`;
  return isLoading || isTeacherLoading ? (
    <CenteredRowMessage
      messageId="loading"
      defaultMessage="Loading..."
      componentKey={`classComponentLoadingMsg_${schoolId}`}
    />
  ) : (
    <>
      <div style={visuallyHidden} aria-live="assertive" role="status" aria-atomic="true">
        {filterMessage}
      </div>
      {classes.length === 0 && teacherClasses.length === 0 ? (
        <CenteredRowMessage
          messageId="noRows"
          defaultMessage="No Rows"
          componentKey={`classComponentNoRowsMsg_${schoolId}`}
        />
      ) : (
        <>
          <TableRow>
            <SearchBar
              id="search-class"
              labelId="searchClass"
              handleSearch={handleSearchKeyChange}
              helperDataText={getPaginatedRows(getClassRows())?.length ? '' : 'No result found'}
            />
            <Checkbox
              multiSelectLevelId={multiSelectLevelId}
              dataTestId="multiSelectTableDataClass"
              ariaLabel={`Enable multi edit for ${schoolName} classes`}
            />
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                padding: 0, border: 'unset',
              }}
              colSpan={7}
            >
              <Collapse in timeout="auto" unmountOnExit>
                <Box sx={{ marginLeft: 0 }}>
                  <Table size="small" aria-label="table classes" data-testid="tableClasses">
                    <TestNameHeaders hidden />
                    <TableBody>
                      {(classes.length > 0 || teacherClasses.length > 0) && getPaginatedRows(getClassRows()).map((classRow) => {
                        const classAssessmentSchedule = parseAssessmentSchedule(classRow.assesments, schoolSchedule);
                        return (
                          <React.Fragment key={`classes_${schoolId}_${classRow.class_id}`}>
                            <TableRow key={`class_${schoolId}_${classRow.class_id}`}>
                              <TableCell
                                sx={{
                                  width: 340, minWidth: 340, paddingLeft: '2rem', paddingRight: 0,
                                }}
                                scope="row"
                                component="th"
                              >
                                {activeMultiSelectId === multiSelectLevelId && (
                                  <Checkbox
                                    multiSelectLevelId={multiSelectLevelId}
                                    multiSelectChildId={`${MultiSelectLevelEnum.ClassPrefix}${classRow.class_id}`}
                                    noTableCell
                                    schoolId={schoolId}
                                    classId={classRow.class_id}
                                    className={classRow.class_name}
                                    assessments={classRow.assesments}
                                    dataTestId="checkboxClass"
                                    ariaLabel={classRow.class_name}
                                  />
                                )}
                                <SVGNameButton
                                  aria-label={`expand row, ${classRow.class_name}`}
                                  size="small"
                                  onClick={() => { handleOpenClick('class', classRow.class_id) }}
                                  disableRipple
                                  sx={{
                                    '&:focus': {
                                      outline: '2px solid black',
                                      outlineOffset: '-3px',
                                      borderRadius: 'unset !important',
                                    },
                                  }}
                                >
                                  {visited.has(`class_${String(classRow.class_id)}`) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  {classRow.class_name}
                                </SVGNameButton>
                              </TableCell>
                              <SchedulerTableView
                                scheduler={classAssessmentSchedule}
                                hasSummerAccess={hasSummerAccess}
                                keyPrefix={`class_table_${classRow.class_id}`}
                                multiSelectLevel={multiSelectLevelId}
                                schoolId={schoolId}
                                classId={classRow.class_id}
                                className={classRow.class_name}
                                dateRange={dateRange}
                              />
                            </TableRow>
                            {visited.has(`class_${String(classRow.class_id)}`) && (
                              <StudentComponent
                                districtId={districtId}
                                schoolId={schoolId}
                                hasSummerAccess={hasSummerAccess}
                                classId={classRow.class_id}
                                classSchedule={classAssessmentSchedule}
                                dateRange={dateRange}
                                classRowName={classRow.class_name}
                              />
                            )}
                          </React.Fragment>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
          <TableRowPagination justifyContent="flex-start">
            <td>
              <TablePagination
                id="pagination-classes"
                rowsPerPageOptions={[5, 10, 20, 40]}
                component="div"
                count={getClassRows().length}
                rowsPerPage={perPageRows}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                labelRowsPerPage={<FormattedMessageRollover id="rowsPerPage" />}
                labelDisplayedRows={({ from, to, count }) => (
                  <PaginationLabel paginationId="classPaginationLabel" from={from} to={to} count={count} />
                )}
                SelectProps={{
                  SelectDisplayProps: {
                    role: 'combobox',
                  },
                  MenuProps: {
                    onKeyDown: (e) => { rowsPerPageSelectedA11y(e, perPageRows) },
                  },
                }}
                sx={{
                  '& p': {
                    marginBottom: '6px !important',
                  },
                }}
                ActionsComponent={({
                  onPageChange, count, rowsPerPage,
                }) => (
                  <PaginationActions
                    page={page}
                    onPageChange={onPageChange}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    paginationLabel="classPaginationLabel"
                  />
                )}
              />
            </td>
          </TableRowPagination>
          <PaginationSpacer />
          <PaginationSpacer />
        </>
      )}
    </>
  )
}

export default ClassComponent;
