import HttpClient from '../apiHost/HttpClient';
import Client from '../../enum/Client';
import KidbizHost from '../apiHost/KidbizHost';
import SmartyAntsHost from '../apiHost/SmartyAntsHost';

export default class HttpClientCreator {
  static createObject(clientType: Client, userId: number): HttpClient| null {
    switch (clientType) {
      case Client.KIDBIZ:
        return KidbizHost.getHttpClient(userId);
      case Client.SMARTY_ANTS:
        return SmartyAntsHost.getHttpClient();
      default:
        return null;
    }
  }
}
