import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import { visuallyHidden } from '@mui/utils';
import TestNameHeaders from './TestNamesComponent';
import ClassComponent from './ClassComponent';
import DistrictComponent from './DistrictComponent';
import SchoolComponent from './SchoolComponent';
import { getDate, parseAssessmentSchedule } from '../util/shared';
import TabSlider from './SliderTab';
import User from '../interfaces/User';
import Roles from '../enum/Roles';
import SpanComponent from './shared/SpanComponent';
import { useAppContext } from '../context/AppContext';
import ProgramEnum from '../enum/Program';
import YearSelector from './YearSelector';
import ConnectionChecker from './ConnectionChecker';
import FormattedMessageRollover from '../lang/FormattedMessage';
import { usePermissions } from './shared/NavBar';
import { PermissionCreatedBy } from '../interfaces/api/Permissions/PermissionsResponse';

const ErrorDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.error.main};
  font-size: larger;
  padding: 0.875rem;
  border-bottom: 2px solid grey;
`

const ContainerDiv = styled.div<{justify: string}>`
  display: flex;
  align-content: start;
  justify-content: ${(props) => (props.justify ? props.justify : 'space-around')};
  @media (max-width: 1322px) {
    flex-direction: row;
    justify-content: start;
  }
`

const AccessMessageDiv = styled.div`
  display: inline-flex;
  align-items: center;
`

interface HomeComponentProps {
  userData: User;
}

export default ({ userData }: HomeComponentProps) => {
  const {
    summer, schoolYearEnd,
    setDisableDatePicker, setAllowReset, user: { isEditable }, httpClient: { getLevelsetSchedule, getDateRange },
  } = useAppContext();

  const { permissionsData, isPermissionsLoadingError } = usePermissions();

  const [limitedAccessMessage, setLimitedAccessMessage] = React.useState<React.ReactNode|null>(null);

  const intl = useIntl();
  const tableNavigationInformation = intl.formatMessage({
    id: 'tableNavigationInformation',
    defaultMessage: 'Use Tab key to navigate the table. Table navigation commands may skip some information',
  });

  const {
    data: districtSchedule = [], isLoading: isDistrictScheduleLoading, isError: isDistrictScheduleError,
  } = getLevelsetSchedule({
    districtId: userData.districtId,
    programId: userData.programId,
    languageId: userData.languageId,
    summer,
    schoolYearEnd,
    isEnabled: (userData.role === Roles.SCHOOL_ADMIN || userData.role === Roles.TEACHER_ADMIN) && !!permissionsData,
  });

  const {
    data: schoolSchedule = [], isLoading: isSchoolScheduleLoading, isError: isSchoolScheduleError,
  } = getLevelsetSchedule({
    districtId: userData.districtId,
    programId: userData.programId,
    languageId: userData.languageId,
    summer,
    schoolYearEnd,
    isEnabled: (userData.role === Roles.TEACHER_ADMIN) && !!permissionsData,
  }, userData.schoolId);

  const { data: dateRange } = getDateRange(
    userData.districtId ?? 0,
    userData.schoolId,
    (userData.role === Roles.TEACHER_ADMIN) && !!permissionsData,
    schoolYearEnd,
  )
  // setting global permissions if school or teacher admin can change the date or just a read only view
  const shouldLoadComponent = (adminType: Roles) => {
    switch (adminType) {
      case Roles.SCHOOL_ADMIN:
        return !isDistrictScheduleLoading && !isDistrictScheduleError;
      case Roles.TEACHER_ADMIN:
        return !isDistrictScheduleLoading && !isDistrictScheduleError && !isSchoolScheduleLoading && !isSchoolScheduleError;
      default:
        return false;
    }
  }
  const hasSummerAccess = () => {
    if (userData.programId === ProgramEnum.LanguageArt) {
      return userData.summerAccess;
    }
    return false;
  }
  const testNameDivRef = useRef<HTMLInputElement>(null)
  const tableRef = useRef<HTMLInputElement>(null)

  const scrollEvent = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft } = event.currentTarget;
    if (testNameDivRef.current) {
      testNameDivRef.current.scrollTo(scrollLeft, 0)
    }
    if (tableRef.current) {
      tableRef.current.scrollTo(scrollLeft, 0)
    }
  }
  const getLimitedAccessMessage = (messageId: string, defaultMessage: string) => (
    <AccessMessageDiv>
      <FormattedMessageRollover
        id={messageId}
        defaultMessage={defaultMessage}
        styles={{ padding: '1rem', border: '2px solid grey' }}
      />
    </AccessMessageDiv>
  )
  useEffect(() => {
    if (!isEditable) {
      return;
    }
    if (permissionsData) {
      switch (userData.role) {
        case Roles.SCHOOL_ADMIN:
          setDisableDatePicker(permissionsData.school_admin_disabled.value);
          setAllowReset(!permissionsData.school_admin_reset_disabled.value);
          if (permissionsData.school_admin_disabled.value) {
            setLimitedAccessMessage(getLimitedAccessMessage(
              'accessDisabledByDA', '',
            ));
          }
          break;
        case Roles.TEACHER_ADMIN:
          setDisableDatePicker(permissionsData.teacher_disabled.value);
          setAllowReset(!permissionsData.teacher_reset_disabled.value);
          if (permissionsData.teacher_disabled.value) {
            const limitedByDA = permissionsData.teacher_disabled.created_by === PermissionCreatedBy.DA;
            setLimitedAccessMessage(getLimitedAccessMessage(
              (limitedByDA ? 'accessDisabledByDA' : 'accessDisabledBySA'), '',
            ));
          } else {
            setLimitedAccessMessage(null);
          }
          break;
        default:
          setDisableDatePicker(false);
          setAllowReset(true);
          setLimitedAccessMessage(null);
      }
    }
  }, [permissionsData])
  return (
    <>
      <ConnectionChecker />
      <YearSelector />
      <TabSlider />
      <ContainerDiv justify="start">
        {limitedAccessMessage}
      </ContainerDiv>
      <TestNameHeaders onScroll={scrollEvent} ref={testNameDivRef} />
      <div id="paginationRegion" style={visuallyHidden} aria-live="assertive" role="status" aria-atomic="true" />
      <Paper>
        <TableContainer component={Paper} onScroll={scrollEvent} ref={tableRef} className="cs-scroll">
          <span id="selectedPage" style={visuallyHidden} aria-hidden="true">selected</span>
          <Table
            sx={{ minWidth: 650, position: 'relative' }}
            aria-label={`Level set ${intl.formatMessage({ id: 'table', defaultMessage: 'table' })}, ${tableNavigationInformation}`}
            data-testid="levelsetTableRoot"
          >
            <TestNameHeaders hidden />
            <TableBody>
              {userData.role === Roles.KBA && <DistrictComponent permissionsLoaded={!!permissionsData} /> }
              {(userData.role === Roles.DISTRICT_ADMIN) && (
                // eslint-disable-next-line max-len
                <DistrictComponent
                  loggedInDistrict={{ district_id: userData.districtId, district_name: userData.districtName, summer_access: userData.summerAccess }}
                  permissionsLoaded={!!permissionsData}
                />
              )}
              {(userData.role === Roles.SCHOOL_ADMIN) && shouldLoadComponent(Roles.SCHOOL_ADMIN) && (
                <>
                  <SpanComponent label="districtName" data={userData.districtName} dateTestId="districtInfoLabel" />
                  {!!permissionsData && (
                    <SchoolComponent
                      loadSASchool
                      schoolId={userData.schoolId}
                      districtSchedule={districtSchedule}
                    />
                  )}
                </>
              )}
              {(userData.role === Roles.TEACHER_ADMIN) && shouldLoadComponent(Roles.TEACHER_ADMIN) && dateRange !== undefined && (
                <>
                  <SpanComponent label="districtName" data={userData.districtName} dateTestId="districtInfoLabel" />
                  <SpanComponent label="schoolName" data={userData.schoolName} dateTestId="schoolInfoLabel" />
                  {!!permissionsData && (
                    <ClassComponent
                      schoolId={userData.schoolId}
                      hasSummerAccess={hasSummerAccess()}
                      isTeacherAdmin
                      schoolSchedule={parseAssessmentSchedule(schoolSchedule, districtSchedule)}
                      dateRange={{
                        schoolStart: getDate(dateRange.school_start),
                        schoolEnd: getDate(dateRange.school_end),
                        summerSchoolStart: getDate(dateRange.summer_school_start),
                        summerSchoolEnd: getDate(dateRange.summer_school_end),
                      }}
                    />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {isPermissionsLoadingError && (
        <ErrorDiv>
          <FormattedMessageRollover
            id="permissionsLoadingError"
            defaultMessage="Error!! Unable to load access permissions, Please try again later"
          />
        </ErrorDiv>
      )}
    </>
  )
}
