import React from 'react'
import styled from '@emotion/styled';
import { TableRow } from '@mui/material';
import FormattedMessageRollover from '../../lang/FormattedMessage';
import PaginationSpacer from './PaginationSpacer';

interface CenteredRowProps {
  messageId: string,
  defaultMessage: string,
  componentKey: string,
}

const LoadingDiv = styled.div`
  width: 98.5%;
  font-weight: bold;
  position: absolute;
  padding-top: 0.875rem;
  display: flex;
  padding-bottom: 0.875rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  justify-content: center;
`
export default ({ messageId, defaultMessage, componentKey }: CenteredRowProps) => (
  <>
    <TableRow key={componentKey}>
      <td>
        <LoadingDiv>
          <FormattedMessageRollover id={messageId} defaultMessage={defaultMessage} />
        </LoadingDiv>
      </td>
    </TableRow>
    <PaginationSpacer />
  </>
);
