import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconButton } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AccessibleTooltip from '../AccessibleTooltip';

export interface LabelDisplayedRowsArgs {
  from: number;
  to: number;
  count: number;
  paginationId: string;
}

interface PaginationActionsProps {
  page: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => void;
  count: number;
  rowsPerPage: number;
  paginationLabel: string;
}

export default ({
  from, to, count, paginationId,
}: LabelDisplayedRowsArgs) => (
  <span id={paginationId}>
    <span>
      <FormattedMessage id="showingRows" defaultMessage="Showing rows" />
      {` ${from}`}
    </span>
    &nbsp;
    <FormattedMessage id="to" defaultMessage="to" />
    &nbsp;
    <span>{`${to} `}</span>
    <FormattedMessage id="outOf" defaultMessage="out of" />
    <span>{` ${count} `}</span>
  </span>
);

const handleFocusAndPaginationAnnounce = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, tableId: string, liveContainerId: string) => {
  (event.target as HTMLElement).focus();
  setTimeout(() => {
    const message = document.querySelector(`#${tableId}`)?.textContent;
    const liveRegion = document.getElementById(liveContainerId);

    if (message && liveRegion) {
      liveRegion.innerHTML = '';
      liveRegion.innerHTML = `<p>${message}</p>`;
    }
  }, 300);
};

export const PaginationActions = ({
  page, onPageChange, count, rowsPerPage, paginationLabel,
}: PaginationActionsProps) => {
  const intl = useIntl();
  const goToPreviousPage = intl.formatMessage({ id: 'goToPreviousPage', defaultMessage: 'Go to previous page' });
  const goToNextPage = intl.formatMessage({ id: 'goToNextPage', defaultMessage: 'Go to next page' });
  return (
    <div style={{ display: 'flex' }}>
      <div>
        <AccessibleTooltip disabled={page === 0} title={goToPreviousPage}>
          <IconButton
            onClick={(event) => {
              onPageChange(event, page - 1);
              handleFocusAndPaginationAnnounce(event, paginationLabel, 'paginationRegion');
            }}
            disabled={page === 0}
            disableRipple
            aria-label={goToPreviousPage}
            sx={{
              '&.Mui-focusVisible': {
                outline: '2px solid black',
                outlineOffset: '-3px',
              },
            }}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </AccessibleTooltip>
      </div>
      <div>
        <AccessibleTooltip
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          title={goToNextPage}
        >
          <IconButton
            onClick={(event) => {
              onPageChange(event, page + 1);
              handleFocusAndPaginationAnnounce(event, paginationLabel, 'paginationRegion');
            }}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            disableRipple
            aria-label={goToNextPage}
            sx={{
              '&.Mui-focusVisible': {
                outline: '2px solid black',
                outlineOffset: '-3px',
              },
            }}
          >
            <NavigateNextIcon />
          </IconButton>
        </AccessibleTooltip>
      </div>
    </div>
  );
}

export const rowsPerPageSelectedA11y = (event: React.KeyboardEvent<HTMLElement>, perPageRows: number) => {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    setTimeout(() => {
      const activeElement = document.activeElement as HTMLElement;
      if (activeElement && activeElement.tagName === 'LI') {
        const selected = perPageRows === Number(activeElement.textContent);
        activeElement.removeAttribute('aria-selected');
        activeElement.setAttribute('aria-describedby', selected ? 'selectedPage' : 'null');
      }
    }, 10)
  }
}
