import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormattedMessage } from 'react-intl';
import District from '../../interfaces/District';

export interface AutoCompleteProps {
  id: string;
  list: District[];
  chosenItem: District | null;
  label: string;
  handleIdChange: (value: District | null) => void;
  disableAutoSelect?: boolean;
}

const AutoCompleteComponent = ({
  id, chosenItem, handleIdChange, label, list, disableAutoSelect,
}: AutoCompleteProps) => (
  <Autocomplete
    id={id}
    sx={{ width: 340 }}
    value={chosenItem}
    loading
    options={list}
    onChange={(event, value: District | null) => {
      if (chosenItem?.district_id !== value?.district_id) {
        handleIdChange(value);
      }
    }}
    clearOnEscape
    getOptionLabel={(option) => option.district_name || ''}
    renderInput={(params) => (
      <TextField
        {...params}
        label={<FormattedMessage id={label} />}
        variant="outlined"
      />
    )}
    renderOption={(props, option) => (
      <li {...props} key={option.district_id}>
        {option.district_name}
      </li>
    )}
    disabled={disableAutoSelect}
  />
);

export default AutoCompleteComponent;
