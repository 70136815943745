import * as React from 'react';
import Alert from '@mui/material/Alert';
import FormattedMessageRollover from '../lang/FormattedMessage';

export default () => {
  const [isOnline, setIsOnline] = React.useState(window.navigator.onLine);

  const updateIsOnline = () => {
    setIsOnline(window.navigator.onLine);
  }

  React.useEffect(() => {
    window.addEventListener('online', updateIsOnline);
    window.addEventListener('offline', updateIsOnline);

    return () => {
      window.removeEventListener('online', updateIsOnline)
      window.removeEventListener('offline', updateIsOnline)
    }
  });
  return !isOnline ? (
    <Alert severity="error" sx={{ display: 'flex', justifyContent: 'center' }}>
      <FormattedMessageRollover id="connectionError" defaultMessage="Error!! Please check your internet connection" />
    </Alert>
  ) : null;
}
