import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { HttpClientConfig } from './HttpClient';
import config from '../../appConfig';
import LocatorTestLevelsRequest from '../../interfaces/api/LocatorTestLevelsRequest';
import LocatorTestLevelsResponse from '../../interfaces/api/LocatorTestLevelsResponse';
import GetUserLocatorTestLevelRequest from '../../interfaces/api/GetUserLocatorTestLevelRequest';
import SaveUserLocatorTestLevelRequest from '../../interfaces/api/SaveUserLocatorTestLevelRequest';
import UserLocatorTestLevelResponse from '../../interfaces/api/UserLocatorTestLevelResponse';

export default class LevelsetHost {
  private static classInstance?: LevelsetHost;

  private readonly instance: AxiosInstance

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      ({ data }: AxiosResponse) => data,
      (error: AxiosError) => Promise.reject(error),
    );
  };

  private constructor() {
    const httpConfig: HttpClientConfig = {
      baseURL: config.LEVELSET_HOST || 'http://localhost',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    }
    this.instance = axios.create(httpConfig)
    this.initializeResponseInterceptor();
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new LevelsetHost();
    }
    return this.classInstance;
  }

  getLocatorTestLevels = async (data: LocatorTestLevelsRequest, accessToken: string)
  : Promise<LocatorTestLevelsResponse[]> => this.instance.post(
    '/api/v1/schedule/get-locator-test-levels',
    JSON.stringify(data),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  getUserLocatorTestLevel = async (data: GetUserLocatorTestLevelRequest, accessToken: string)
  : Promise<UserLocatorTestLevelResponse> => this.instance.post(
    '/api/v1/schedule/get-user-locator-test-level',
    JSON.stringify(data),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  saveUserLocatorTestLevel = async (data: SaveUserLocatorTestLevelRequest, accessToken: string)
  : Promise<UserLocatorTestLevelResponse> => this.instance.post(
    '/api/v1/schedule/save-user-locator-test-level',
    JSON.stringify(data),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
}
