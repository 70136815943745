import * as React from 'react';
import { createTheme, ThemeProvider as ThemeProviderReact } from '@mui/material/styles';

declare module '@emotion/react' {
  export interface Theme {
    palette: {
      error: {
        main: string
      }
    }
  }
}

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontWeight: 'inherit',
          '& .ls-ui-MuiOutlinedInput-notchedOutline': {
            borderColor: 'currentColor',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 'inherit',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: 'currentColor',
          '&:focus': {
            outline: '2px solid black',
            outlineOffset: '2px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            outline: '2px solid black',
            outlineOffset: '4px',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .ls-ui-MuiOutlinedInput-notchedOutline': {
            borderColor: 'currentColor',
          },
        },
        listbox: {
          '& .Mui-focusVisible': {
            outline: '2px solid black',
            outlineOffset: '-2px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            outline: '2px solid black',
            outlineOffset: '-4px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:focus': {
            outline: '2px solid black',
            outlineOffset: '-2px',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        menuItem: {
          '&:focus': {
            outline: '2px solid black',
            outlineOffset: '-2px',
          },
        },
        select: {
          '&:focus': {
            outline: '2px solid black',
            outlineOffset: '-2px',
          },
        },
      },
    },
  },
  palette: {
    text: {
      disabled: 'rgba(0, 0, 0, 0.6)',
    },
    error: {
      main: '#d32f2f',
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.6)',
    },
  },
});

const ThemeProvider: React.FC = ({ children }) => (
  <ThemeProviderReact theme={theme}>
    {children}
  </ThemeProviderReact>
)

export default ThemeProvider;
