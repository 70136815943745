import LevelSetSchedule from '../interfaces/LevelSetSchedule';
import MultiSelectLevelEnum from '../enum/MultiSelectLevel';

// eslint-disable-next-line import/prefer-default-export
export const parseAssessmentSchedule = (
  currentAssessments: LevelSetSchedule[],
  compareWith: LevelSetSchedule[],
): LevelSetSchedule[] => {
  const arr = [];
  const currentAssessmentsArr = [...currentAssessments];
  for (let i = 0; i < currentAssessmentsArr.length; i += 1) {
    const assessment = currentAssessmentsArr[i];
    for (let j = 0; j < compareWith.length; j += 1) {
      if (assessment.assessment_id === compareWith[j].assessment_id) {
        assessment.schedule_time = assessment.id === null || assessment.date_modified === null
          ? compareWith[j].schedule_time : assessment.schedule_time;
        break;
      }
    }
    arr.push(assessment);
  }
  return arr;
}

// it can format string dynamically, but make sure to provide correct number of placeholder and placeholder values
function formatString(str: MultiSelectLevelEnum, values: number[]): string {
  let index = 0;
  const stringParts = str.toString().split('%d%');
  let formattedString = '';
  stringParts.map((s) => {
    formattedString = formattedString.concat(s, index < values.length ? values[index].toString() : '')
    index += 1;
    return true;
  });
  return formattedString;
}

export const getMultiSelectKey = (str:MultiSelectLevelEnum, values: number[]) => formatString(str, values);

const getKeyValue = <T extends object>(key:keyof T, obj: T) => obj[key];

// This function can convert objects to form data only if object contains string and simple arrays
// does not support multidimensional array, blobs, FileInstances, DateObjects
// Always try to pass string as a value otherwise add new if blocks with specified type to handle those cases
export const convertToFormData = <T extends object>(obj: T, fd: FormData, parentKey?: string|undefined, index?: number|undefined) => {
  const formData = fd ?? new FormData();
  Object.keys(obj).forEach((key) => {
    const value = getKeyValue<T>(key as keyof T, obj);
    if (Array.isArray(value)) {
      let newIndex = 0;
      value.forEach((v) => {
        if (typeof v === 'object') {
          // making use of recursion
          convertToFormData(v, fd, key, newIndex);
          newIndex += 1;
        }
      });
    } else {
      const dataKey = parentKey && index !== undefined ? `${parentKey}[${index}][${key}]` : key;
      try {
        if (value === null) {
          formData.append(dataKey, '');
        } else {
          formData.append(dataKey, String(value));
        }
      } catch (err) {
        formData.append(dataKey, '');
      }
    }
  })
  return formData;
}

export const getDate = (date: string|null): Date|null => ((date && date.length > 5) ? new Date(date.replace(/-/g, '/')) : null);

export function isValidObject<T>(obj:unknown, property:string[]): obj is T {
  let isValidObj = true;
  if (typeof obj === 'object' && obj !== null) {
    property.forEach((val) => {
      isValidObj = val in obj;
    })
    return isValidObj;
  }
  return false;
}

// getMonth starts in 0, where 0 is Jan and July is 6
export const getCurrentSchoolYear = () => (new Date().getMonth() < 6 ? new Date().getFullYear() : new Date().getFullYear() + 1)
