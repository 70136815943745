import * as React from 'react';
import Switch from '@mui/material/Switch';
import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from '@emotion/styled';
import FormattedMessageRollover from '../lang/FormattedMessage';
import { useAppContext } from '../context/AppContext';
import SummerEnum from '../enum/Summer';
import { getCurrentSchoolYear } from '../util/shared';
import PermissionEnum from '../enum/Permission';
import PermissionsResponse, { PermissionCreatedBy } from '../interfaces/api/Permissions/PermissionsResponse';
import Roles from '../enum/Roles';

const PermissionInfoDiv = styled.div`
  font-style: italic;
  margin-left: 3rem;
`
const label = { inputProps: { 'aria-label': 'Switch Include Summer' } };

interface SummerSwitchProps {
  onSwitchToggle: (permissionId: PermissionEnum, newValue: boolean, setState: React.Dispatch<boolean>) => Promise<boolean>;
  isLoading: boolean;
  permissionData: PermissionsResponse
}
const BasicSwitches = ({ onSwitchToggle, isLoading, permissionData } : SummerSwitchProps) => {
  const {
    summer, setIsSummer, schoolYearEnd, user: { role },
  } = useAppContext();

  const [checked, setChecked] = React.useState<boolean>(!permissionData.levelset_scheduler_include_summer.value);
  const [shouldRender, setShouldRender] = React.useState(getCurrentSchoolYear() >= schoolYearEnd);

  const onSwitchChange = async () => {
    const newValue = !checked;
    const res = await onSwitchToggle(PermissionEnum.SummerToggleDisabled, newValue, setChecked);
    if (res) {
      setIsSummer(newValue ? SummerEnum.Yes : SummerEnum.No);
    }
  }

  const isSummerSwitchDisabled = () => {
    if (isLoading || role === Roles.TEACHER_ADMIN) {
      return true;
    }
    return permissionData.levelset_scheduler_include_summer.value
      && permissionData.levelset_scheduler_include_summer.created_by === PermissionCreatedBy.DA && role === Roles.SCHOOL_ADMIN;
  }

  const getPermissionEnabledText = () => {
    if (permissionData.levelset_scheduler_include_summer.value) {
      switch (permissionData.levelset_scheduler_include_summer.created_by) {
        case PermissionCreatedBy.DA:
          return (role === Roles.SCHOOL_ADMIN || role === Roles.TEACHER_ADMIN) ? 'enabledByDA' : 'enabled';
        case PermissionCreatedBy.SA:
          return (role === Roles.TEACHER_ADMIN) ? 'enabledBySA' : 'enabled';
        default:
          return 'enabled';
      }
    }
    return 'enabled';
  }

  React.useEffect(() => {
    setShouldRender(getCurrentSchoolYear() >= schoolYearEnd);
    setChecked(permissionData.levelset_scheduler_include_summer.value);
  }, [summer, permissionData, schoolYearEnd])
  return shouldRender ? (
    <Grid item xs={12} sm={6} md={4} lg={4} minWidth={370}>
      <FormControlLabel
        control={(
          <Switch
            checked={checked}
            data-testid="summerSwitchElement"
            {...label}
            disabled={isSummerSwitchDisabled()}
            onChange={() => onSwitchChange()}
          />
        )}
        label={(
          <FormattedMessageRollover id="includeSummer" defaultMessage="Include Summer" />
        )}
      />
      {checked && (
        <PermissionInfoDiv>
          <FormattedMessageRollover id={getPermissionEnabledText()} defaultMessage="enabled" />
        </PermissionInfoDiv>
      )}
    </Grid>
  ) : null;
}

export default BasicSwitches;
