import React from 'react';
import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem, Select } from '@mui/material';
import styled from '@emotion/styled';
import { useAppContext } from '../context/AppContext';
import FormattedMessageRollover from '../lang/FormattedMessage';
import ProgramEnum from '../enum/Program';
import { getCurrentSchoolYear } from '../util/shared';

const YearSelector = () => {
  const {
    schoolYearEnd, setSchoolYearEnd, isLocal, programId,
  } = useAppContext();
  const buildMenuItems = (): number[] => {
    const currentYearEnd = getCurrentSchoolYear();
    if (new Date().getMonth() < 6) return [currentYearEnd + 1, currentYearEnd, currentYearEnd - 1];
    return [currentYearEnd, currentYearEnd - 1];
  }

  const getTop = () => {
    if (isLocal) {
      return '5rem'
    }
    return programId === (ProgramEnum.LanguageArt) ? '8.5rem' : '12.5rem'
  }

  const getTopForZoomedViewport = () => {
    if (isLocal) {
      return '5rem'
    }
    return programId === (ProgramEnum.LanguageArt) ? '9.5rem' : '12.5rem'
  }
  const VHSpan = styled.span`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  `;
  const YearSelectorDiv = styled.div`
    position: absolute;
    top: ${getTop()};
    right:0.3rem;
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    z-index: 100;
    box-shadow: rgb(0 0 0 / 50%) 0px 2px 10px;
    @media (max-width: 320px) {
      left: 0.5rem;
      right: auto;
      top: ${getTopForZoomedViewport()};
    }
  `
  return (
    <YearSelectorDiv>
      <FormControl
        size="small"
        sx={{
          m: 1,
          '@media (max-width: 320px)': {
            minWidth: 150,
          },
        }}
      >
        <InputLabel id="school-year-select">
          <FormattedMessageRollover id="schoolYear" defaultMessage="School Year" />
        </InputLabel>
        <Select
          id="school-year-select-label"
          labelId="school-year-select"
          value={schoolYearEnd}
          label="schoolYear"
          onChange={(event) => {
            setSchoolYearEnd(event.target.value as number)
          }}
        >
          {buildMenuItems().map((yearEnd) => (
            <MenuItem value={yearEnd} key={`schoolYear_${yearEnd}`}>
              {yearEnd - 1}
              {' '}
              -
              {' '}
              {yearEnd}
              {yearEnd === schoolYearEnd && <VHSpan> (Selected)</VHSpan>}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </YearSelectorDiv>
  );
}

export default YearSelector;
