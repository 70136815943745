import React from 'react';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';
import FormattedMessageRollover from '../../lang/FormattedMessage';

const SpanDiv = styled.div`
  padding: 1rem;
  width: fit-content;
  margin-left: 1rem;
  border: 2px solid grey;
  margin-bottom: 0.3rem;
  margin-top: 0.2rem;
  box-shadow: 5px 5px 2px  #5d6171;
`

const SpanHeading = styled.span`
  font-weight: bold;
`

interface SpanProps {
  label: string
  data: string
  dateTestId: string
}
export default ({ label, data, dateTestId } : SpanProps) => (
  <TableRow>
    <td>
      <SpanDiv data-testid={dateTestId}>
        <SpanHeading>
          <FormattedMessageRollover id={label} />
          :
        </SpanHeading>
        <span>
          {data}
        </span>
      </SpanDiv>
    </td>
  </TableRow>
)
