import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';
import FormattedMessageRollover from '../../lang/FormattedMessage';

interface AlertDialogProps {
  dialogTitle: string,
  dialogText: string,
  onClose: () => void
}
export default function AlertDialog({
  dialogTitle, dialogText, onClose,
}: AlertDialogProps) {
  const [open, setOpen] = React.useState(!!dialogText);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  useEffect(() => {
    setOpen(!!dialogText);
  }, [dialogText])
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {!!dialogTitle && <FormattedMessageRollover id={dialogTitle} defaultMessage={dialogTitle} />}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!!dialogText && <FormattedMessageRollover id={dialogText} defaultMessage={dialogText} /> }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            autoFocus
            onClick={handleClose}
            sx={{
              '&.Mui-focusVisible': {
                outline: '2px solid black',
                outlineOffset: '-3px',
              },
            }}
          >
            <FormattedMessageRollover id="ok" defaultMessage="ok" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
