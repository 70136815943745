// eslint-disable-next-line no-shadow
export enum Permission {
  SchoolAdminDisabled = 84,
  TeacherDisabled = 85,
  SchoolAdminResetDisabled = 87,
  TeacherResetDisabled = 88,
  InterimIITestDisabled = 92,
  SummerToggleDisabled = 93,
}
export default Permission;
