// eslint-disable-next-line no-shadow
enum MultiSelectLevel {
  District=-1,
  Null = 0,
  School = 1,
  SchoolPrefix = 'school_',
  ClassPrefix = 'school_class_',
  StudentPrefix = 'school_class_student'
}

export default MultiSelectLevel;
