const liveAnnounce = (message: string, idLiveContainer: string) => {
  const live = document.getElementById(`${idLiveContainer}`) as HTMLElement;
  if (live) {
    live.innerHTML = '';
    setTimeout(() => {
      live.innerHTML = `<p>${message} selected</p>`;
    }, 500);
  }
};
export default liveAnnounce;
