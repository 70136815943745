import {
  createContext, useContext,
} from 'react';
import User from '../interfaces/User'
import HttpClient from '../service/apiHost/HttpClient';
import SummerEnum from '../enum/Summer';
import LanguageEnum from '../enum/Language';
import MultiSelectLevelEnum from '../enum/MultiSelectLevel';
import MultiSelectProps from '../interfaces/MultiSelectProps';

export interface AppContextInterface {
  user: User,
  programId: string,
  languageId: LanguageEnum,
  setSelectedLanguageId: (newLang: LanguageEnum) => void,
  districtId: number,
  setSelectedDistrictId: (districtId: number) => void,
  summer: SummerEnum,
  setIsSummer: (isSummer: SummerEnum) => void,
  httpClient: HttpClient
  activeMultiSelectId: MultiSelectLevelEnum|string,
  setActiveMultiSelectId: (newMultiSelectId: MultiSelectLevelEnum|string) => void
  multiSelectedRecordsMap: Map<string, MultiSelectProps>
  disableDatePicker: boolean,
  setDisableDatePicker: (newValue: boolean) => void
  allowReset: boolean,
  setAllowReset: (newValue: boolean) => void
  schoolYearEnd: number,
  setSchoolYearEnd: (newValue: number) => void,
  isInterimIITestDisabled: boolean,
  setInterimIITestDisabled: (newValue: boolean) => void,
  isLocal: boolean,
}

const AppContext = createContext<AppContextInterface|undefined>(
  undefined,
);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('App Context is undefined')
  }

  return context
}

export default AppContext;
