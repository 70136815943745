import React from 'react';
import { IntlProvider } from 'react-intl';
import English from '../lang/en.json';
import Spanish from '../lang/es.json';
import SpanishSupport from '../lang/en-es.json';

interface LanguageProviderProps {
  languageId: string
}

const LanguageProvider: React.FC<LanguageProviderProps> = ({ languageId, children }) => {
  let messages;
  let locale;

  switch (languageId) {
    case '2':
      messages = Spanish;
      locale = 'es'
      break;
    case '3':
      messages = SpanishSupport
      locale = 'en-es';
      break;
    default:
      messages = English;
      locale = 'en';
  }

  return (
    <IntlProvider locale={locale} messages={messages} defaultLocale={locale}>
      {children}
    </IntlProvider>
  )
}

export default LanguageProvider;
