import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormattedMessageRollover from '../lang/FormattedMessage';
import { useAppContext } from '../context/AppContext';
import SummerEnum from '../enum/Summer';
import { testScheduleHeaders } from './shared/SchedulerTableView';

interface TestNameProps {
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  hidden?: boolean;
}

const visuallyHiddenStyle = {
  position: 'absolute',
  width: '1px',
  height: '1px',
  margin: '-1px',
  padding: 0,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  border: 0,
};

export default React.forwardRef<HTMLInputElement, TestNameProps>(({ hidden, onScroll }: TestNameProps, ref) => {
  const { summer, isInterimIITestDisabled } = useAppContext();
  const disabledTests = [isInterimIITestDisabled ? 3 : 0];
  return (
    hidden ? (
      <TableHead sx={hidden ? visuallyHiddenStyle : null}>
        <TableRow data-testid="tableRowTestNames">
          <TableCell
            component="td"
            colSpan={7}
            sx={{
              width: 340, minWidth: 340,
            }}
            align="center"
          />
          {testScheduleHeaders.map((header) => {
            if (disabledTests.includes(Number(header.id))) {
              return null;
            }
            if (header.summer === SummerEnum.No || header.summer === summer) {
              return (
                <TableCell
                  key={`${header.labelId}_${header.id}`}
                  align="center"
                  sx={{ minWidth: '235px' }}
                  scope="col"
                >
                  <FormattedMessageRollover id={header.labelId} defaultMessage={header.defaultLabel} />
                </TableCell>
              )
            }
            return null;
          })}
        </TableRow>
      </TableHead>
    ) : (
      <TableContainer
        component={Paper}
        onScroll={onScroll}
        ref={ref}
        className="cs-scroll"
        aria-hidden="true"
      >
        <Table aria-label="Test Names Header">
          <TableHead>
            <TableRow data-testid="tableRowTestNames">
              <TableCell
                component="td"
                sx={{
                  width: 340, minWidth: 340,
                }}
                align="center"
              />
              {testScheduleHeaders.map((header) => {
                if (disabledTests.includes(Number(header.id))) {
                  return null;
                }
                if (header.summer === SummerEnum.No || header.summer === summer) {
                  return (
                    <TableCell
                      key={`${header.labelId}_${header.id}`}
                      align="center"
                      sx={{ minWidth: '235px' }}
                      scope="col"
                    >
                      <FormattedMessageRollover id={header.labelId} defaultMessage={header.defaultLabel} />
                    </TableCell>
                  )
                }
                return null;
              })}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    )
  );
});
