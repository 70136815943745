import React, { useEffect, useRef, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import District from '../interfaces/District';
import AutoCompleteComponent from './shared/AutoComplete';
import SchoolComponent from './SchoolComponent';
import { getDate } from '../util/shared';
import DateRange from '../interfaces/DateRange';
import { useAppContext } from '../context/AppContext';
import SchedulerTableView from './shared/SchedulerTableView';
import MultiSelectLevelEnum from '../enum/MultiSelectLevel';
import DateRangeResponse from '../interfaces/api/DateRangeResponse';
import Roles from '../enum/Roles';

interface DistrictComponentProps {
  loggedInDistrict?: District
  permissionsLoaded: boolean
}

export default ({ loggedInDistrict, permissionsLoaded } : DistrictComponentProps) => {
  const {
    // eslint-disable-next-line max-len
    districtId, setSelectedDistrictId, user: { role }, languageId, programId, summer, schoolYearEnd, httpClient: { getDateRange, getLevelsetSchedule, getDistrictData },
  } = useAppContext();
  const { data: districts = [] } = getDistrictData(!loggedInDistrict, programId);
  const [chosenDistrict, setChosenDistrict] = useState<District | null>(loggedInDistrict || null);

  const { data: dateRange } = getDateRange(districtId ?? 0, 0, !!districtId, schoolYearEnd)
  const {
    isIdle: isGetScheduleIdle, data: districtSchedule = [], isLoading: isScheduleLoading, isError: isScheduleError,
  } = getLevelsetSchedule({
    districtId: districtId ?? 0, programId, languageId, summer, isEnabled: !!districtId && !!dateRange && permissionsLoaded, schoolYearEnd,
  });
  const initialRender = useRef(true);
  const handleDistrictIdChange = (value: React.SetStateAction<District | null>): void => {
    setChosenDistrict(value);
    if (value === null) {
      setSelectedDistrictId(0);
    } else {
      const district = value as District;
      setSelectedDistrictId(district.district_id);
    }
  }
  const parseDateRange = (dateRangeData: DateRangeResponse): DateRange => ({
    schoolStart: getDate(dateRangeData.school_start),
    schoolEnd: getDate(dateRangeData.school_end),
    summerSchoolStart: getDate(dateRangeData.summer_school_start),
    summerSchoolEnd: getDate(dateRangeData.summer_school_end),
  })
  const shouldLoadSchoolComponent = () => !isGetScheduleIdle && !isScheduleLoading && !isScheduleError;
  const setCurrentSelectedDistrict = () => {
    const d = districts.find((district) => district.district_id === districtId);
    if (d) {
      setChosenDistrict(d);
    }
  }
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      if (role === Roles.KBA) {
        setCurrentSelectedDistrict();
      }
    }
  }, [languageId, schoolYearEnd])

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: 340, paddingLeft: '0' }}>
          { districts && (
            <AutoCompleteComponent
              id="district_autocomplete"
              list={districts}
              chosenItem={chosenDistrict}
              label="chooseDistrict"
              handleIdChange={handleDistrictIdChange}
              disableAutoSelect={loggedInDistrict !== undefined && loggedInDistrict !== null}
            />
          )}
        </TableCell>
        {(chosenDistrict && shouldLoadSchoolComponent()) && dateRange !== undefined && (
          <>
            <SchedulerTableView
              scheduler={districtSchedule}
              hasSummerAccess={summer && chosenDistrict.summer_access}
              keyPrefix="district"
              districtName={chosenDistrict.district_name}
              multiSelectLevel={MultiSelectLevelEnum.District}
              dateRange={parseDateRange(dateRange)}
            />
          </>
        )}
      </TableRow>
      {chosenDistrict?.district_id && districtSchedule.length > 0 && dateRange !== undefined ? (
        <>
          <SchoolComponent districtSchedule={districtSchedule} />
        </>
      ) : null}
    </>
  )
}
