import React from 'react';
import TableCell from '@mui/material/TableCell';
import LevelSetSchedule from '../../interfaces/LevelSetSchedule';
import { useAppContext } from '../../context/AppContext';
import SummerEnum from '../../enum/Summer';
import DatePicker from './DatePicker';
import MultiSelectLevelEnum from '../../enum/MultiSelectLevel';
import DateRange from '../../interfaces/DateRange';
import LevelSetPopup from '../popup/LevelSetPopup';

interface SchedulerProps {
  scheduler: LevelSetSchedule[],
  hasSummerAccess: boolean,
  keyPrefix: string,
  districtName?: string,
  schoolId?: number,
  schoolName?: string,
  multiSelectLevel: MultiSelectLevelEnum|string
  classId?: number,
  className?: string,
  studentId?: number,
  studentName?: string,
  columnWidth?: number,
  dateRange: DateRange,
}

interface SchedulerTableProps {
  id: string,
  name: string,
  labelId: string,
  defaultLabel: string,
  summer: SummerEnum,
  dataTestIdSuffix: string,
}

export const testScheduleHeaders: SchedulerTableProps[] = [
  {
    id: '1',
    name: 'assessment_name',
    labelId: 'test.preTest',
    defaultLabel: 'Pre-Test',
    summer: SummerEnum.No,
    dataTestIdSuffix: 'PreTest',
  },
  {
    id: '2',
    name: 'assessment_name',
    labelId: 'test.interimTest',
    defaultLabel: 'Interim Test',
    summer: SummerEnum.No,
    dataTestIdSuffix: 'InterimTest',
  },
  {
    id: '3',
    name: 'assessment_name',
    labelId: 'test.interimSecondTest',
    defaultLabel: 'Interim II Test',
    summer: SummerEnum.No,
    dataTestIdSuffix: 'InterimTwoTest',
  },
  {
    id: '4',
    name: 'assessment_name',
    labelId: 'test.postTest',
    defaultLabel: 'Post-Test',
    summer: SummerEnum.No,
    dataTestIdSuffix: 'PostTest',
  },
  {
    id: '5',
    name: 'assessment_name',
    labelId: 'test.summerPreTest',
    defaultLabel: 'Summer Pre-Test',
    summer: SummerEnum.Yes,
    dataTestIdSuffix: 'SummerPreTest',
  },
  {
    id: '6',
    name: 'assessment_name',
    labelId: 'test.summerPostTest',
    defaultLabel: 'Summer Post-Test',
    summer: SummerEnum.Yes,
    dataTestIdSuffix: 'SummerPostTest',
  },
]

const getAssessmentLabelId = (assessmentId: string): string => {
  const index = parseInt(assessmentId, 10);
  if (Number.isNaN(index) || index > 6) {
    return '';
  }
  return testScheduleHeaders[index - 1] ? testScheduleHeaders[index - 1].labelId : '';
}

export default ({
  scheduler, hasSummerAccess, keyPrefix, multiSelectLevel, columnWidth,
  districtName, schoolId, schoolName, classId, className, studentId, studentName,
  dateRange,
}: SchedulerProps) => {
  const {
    summer, districtId, isInterimIITestDisabled,
  } = useAppContext();

  const displayScoreOrDate = (row : LevelSetSchedule, isSummerAssessment: boolean) => {
    const formattedScore = (score : number) => {
      const roundScore = Math.round(score / 5) * 5;
      if (roundScore < 0) {
        return `BR${Math.abs(roundScore)}`;
      }
      return roundScore;
    }
    if (row.lexiles && row.lexiles.length > 0) {
      const displayScore = (row.lexiles[0].a3k_levelset === '1'
        ? `${formattedScore(row.lexiles[0].score)}L`
        : `${formattedScore(row.lexiles[0].score)}L*`
      );
      return (
        <LevelSetPopup
          assessmentIdLabel={getAssessmentLabelId(row.assessment_id)}
          studentId={studentId ?? 0}
          studentName={studentName ?? ''}
          classId={classId ?? 0}
          displayScore={displayScore}
          scores={row.lexiles}
        />
      )
    }
    return (
      <DatePicker
        assessmentScheduleId={row.id}
        scheduleTime={row.schedule_time ? row.schedule_time : 'UNSCHEDULED'}
        override={row.override}
        autoSchedule={row.auto_schedule}
        assessmentDateId={row.assessment_date_id}
        assessmentId={row.assessment_id}
        assessmentIdLabel={getAssessmentLabelId(row.assessment_id)}
        districtId={districtId}
        districtName={districtName ?? ''}
        schoolId={schoolId ?? 0}
        schoolName={schoolName ?? ''}
        classId={classId ?? 0}
        className={className ?? ''}
        studentId={studentId ?? 0}
        studentName={studentName ?? ''}
        multiSelectLevel={multiSelectLevel}
        schoolStart={!isSummerAssessment ? (dateRange.schoolStart ?? null) : (dateRange.summerSchoolStart ?? null)}
        schoolEnd={!isSummerAssessment ? (dateRange.schoolEnd ?? null) : (dateRange.summerSchoolEnd ?? null)}
      />
    )
  }

  const tableData = () => testScheduleHeaders.map((header) => {
    const disabledTests = [isInterimIITestDisabled ? 3 : 0];
    let component = (
      <TableCell
        key={`${keyPrefix}_schedule_${header.id}`}
        sx={{ width: columnWidth ? `${columnWidth}px` : '235px', minWidth: columnWidth ? `${columnWidth}px` : '235px' }}
      />
    )
    if (disabledTests.includes(Number(header.id))) {
      return null;
    }
    if (header.summer === SummerEnum.No || header.summer === summer) {
      const shouldRenderDatePicker = (header.summer === SummerEnum.No && dateRange.schoolStart !== null && dateRange.schoolEnd !== null)
        || (header.summer === SummerEnum.Yes && dateRange.summerSchoolStart !== null && dateRange.summerSchoolEnd !== null);
      for (let i = 0; i < scheduler.length; i += 1) {
        if (header.id === scheduler[i].assessment_id) {
          component = shouldRenderDatePicker ? (
            <TableCell
              key={`${keyPrefix}_schedule_${scheduler[i].assessment_id}`}
              align="center"
              sx={{ width: columnWidth ? `${columnWidth}px` : '235px', minWidth: columnWidth ? `${columnWidth}px` : '235px' }}
              data-testid={`${keyPrefix.split('_')[0]}${header.dataTestIdSuffix}`}
            >
              {(header.summer === SummerEnum.No || hasSummerAccess)
                && displayScoreOrDate(scheduler[i], header.summer === SummerEnum.Yes)}
            </TableCell>
          ) : (
            <TableCell
              key={`${keyPrefix}_schedule_${scheduler[i].assessment_id}`}
              sx={{ width: columnWidth ? `${columnWidth}px` : '235px', minWidth: columnWidth ? `${columnWidth}px` : '235px' }}
            />
          )
          break;
        }
      }
      return component;
    }
    return null;
  });
  return (
    <>
      {tableData()}
    </>
  )
}
