import { useQuery, UseQueryResult } from 'react-query';
import HttpClient, { HttpClientConfig } from './HttpClient';
import District from '../../interfaces/District';
import config from '../../appConfig';
import { ClassAPIData, LevelsetAPIBase, StudentAPIData } from '../../interfaces/api';
import School from '../../interfaces/School';
import Class from '../../interfaces/Class';
import LevelSetSchedule from '../../interfaces/LevelSetSchedule';
import Student from '../../interfaces/Student';
import PostScheduleObject from '../../interfaces/PostScheduleObject';
import ErrorDetail from '../../util/ErrorDetail';
import PermissionsResponse from '../../interfaces/api/Permissions/PermissionsResponse';
import PermissionsRequest from '../../interfaces/api/Permissions/PermissionsRequest';
import SavePermissionsRequest from '../../interfaces/api/Permissions/SavePermissionsRequest';
import DateRangeResponse from '../../interfaces/api/DateRangeResponse';
import GetResetEligibilityRequest from '../../interfaces/api/reset/GetResetEligibilityRequest';
import GetResetEligibilityResponse from '../../interfaces/api/reset/GetResetEligibilityResponse';
import ResetLevelSetTestRequest from '../../interfaces/api/reset/ResetLevelSetTestRequest';
import LocatorTestLevelsResponse from '../../interfaces/api/LocatorTestLevelsResponse';
import UserLocatorTestLevelResponse from '../../interfaces/api/UserLocatorTestLevelResponse';

export default class SmartyAntsHost extends HttpClient {
  private static classInstance?: SmartyAntsHost;

  private constructor() {
    const token = localStorage.getItem('token')
    const httpConfig: HttpClientConfig = {
      baseURL: config.SA_HOST || 'http://localhost',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json', Authorization: `Bearer ${token}` },
    }
    super(httpConfig);
  }

  public static getHttpClient() {
    if (!this.classInstance) {
      this.classInstance = new SmartyAntsHost();
    }
    return this.classInstance;
  }

  getDistrictData = (isEnabled: boolean, programId: string): UseQueryResult<District[]> => useQuery(
    ['districts', programId],
    async (): Promise<Array<District>> => await this.getInstance().get(`/api/v1/districts/program/${programId}/`) as District[],
    {
      enabled: isEnabled,
      staleTime: 1000,
    },
  )

  getSchoolData = ({
    districtId, programId, languageId, isEnabled, summer,
  }: LevelsetAPIBase): UseQueryResult<School[], unknown> => useQuery(
    ['schools', districtId, programId, languageId, summer],
    async (): Promise<Array<School>> => await this.getInstance().get(
      `api/v1/levelset/schedule/district/${districtId}/program/${programId}/language/${languageId}/schools?summer=${summer}`,
    ) as School[],
    {
      enabled: isEnabled,
      staleTime: 1000,
    },
  )

  getClassData = ({
    districtId, schoolId, programId, languageId, summer, isEnabled,
  }: ClassAPIData): UseQueryResult<Class[], unknown> => useQuery(
    ['classes', schoolId, programId, languageId, summer],
    async (): Promise<Array<Class>> => await this.getInstance().get(
      `/api/v1/levelset/schedule/district/${districtId}/school/${schoolId}/program/${programId}/language/${languageId}/classes?summer=${summer}`,
    ) as Class[],
    {
      enabled: isEnabled,
      staleTime: 1000,
    },
  )

  getLevelsetSchedule = ({
    districtId, programId, languageId, isEnabled, summer,
  }: LevelsetAPIBase, schoolId = 0, classId = 0, userId = 0): UseQueryResult<LevelSetSchedule[], unknown> => {
    const bodyFormData = new FormData();
    bodyFormData.append('district_id', districtId ? `${districtId}` : '0');
    bodyFormData.append('school_id', `${schoolId}`);
    bodyFormData.append('class_id', `${classId}`);
    bodyFormData.append('user_id', `${userId}`);
    bodyFormData.append('program_id', programId);
    bodyFormData.append('language_id', languageId);
    bodyFormData.append('summer', summer);
    return useQuery(
      ['districtSchedule', districtId, programId, languageId, summer, schoolId, classId, userId],
      async (): Promise<Array<LevelSetSchedule>> => await this.getInstance().post(
        'api/v1/levelset/schedule', bodyFormData,
      ) as LevelSetSchedule[],
      {
        enabled: isEnabled && districtId !== null && districtId !== undefined,
        staleTime: 500,
      },
    );
  }

  getSchoolAdminData = ({
    districtId, programId, languageId, isEnabled, summer,
  }: LevelsetAPIBase, userId: number): UseQueryResult<School[], unknown> => useQuery(
    ['schools', userId, districtId, programId, languageId, summer],
    async (): Promise<Array<School>> => await this.getInstance().get(
      `api/v1/levelset/schedule/district/${districtId}/user/${userId}/program/${programId}/language/${languageId}/schools?summer=${summer}`,
    ) as School[],
    {
      enabled: isEnabled,
      staleTime: 1000,
    },
  )

  getStudentData = ({
    districtId, schoolId, programId, languageId, classId, summer, isEnabled,
  }: StudentAPIData): UseQueryResult<Student[], unknown> => useQuery(
    ['students', districtId, classId, languageId, summer],
    async (): Promise<Array<Student>> => await
    // eslint-disable-next-line max-len
    this.getInstance().get(`/api/v1/levelset/schedule/district/${districtId}/school/${schoolId}/class/${classId}/program/${programId}/language/${languageId}/students?summer=${summer}`) as Student[],
    {
      enabled: isEnabled,
      staleTime: 1000,
    },
  )

  getTeacherAdminData = ({
    districtId, programId, languageId, isEnabled, summer,
  }: LevelsetAPIBase, userId: number, schoolId: number): UseQueryResult<Class[], unknown> => useQuery(
    ['teacher', userId, districtId, programId, languageId, summer],
    async (): Promise<Array<Class>> => await this.getInstance().get(
      // eslint-disable-next-line max-len
      `api/v1/levelset/schedule/teacher/${userId}/district/${districtId}/school/${schoolId}/program/${programId}/language/${languageId}/classes?summer=${summer}`,
    ) as Class[],
    {
      enabled: isEnabled,
      staleTime: 1000,
    },
  )

  postLevelSetSchedule = async (data: PostScheduleObject): Promise<string[]|ErrorDetail> => {
    try {
      return await this.getInstance()
        .post('/api/v1/levelset/schedule/save',
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }) as string[];
    } catch (err) {
      return new ErrorDetail(err);
    }
  }

  getPermissions = ({
    districtId, programId, schoolId,
  }: PermissionsRequest): UseQueryResult<PermissionsResponse, unknown> => {
    const formData = new FormData();
    formData.append('district_id', `${districtId}`);
    formData.append('school_id', `${schoolId ?? 0}`);
    formData.append('program_id', `${programId}`);
    return useQuery(
      ['permissions', districtId, programId, schoolId],
      async (): Promise<PermissionsResponse> => await this.getInstance().post(
        '/api/v1/levelset/schedule/permissions', formData,
      ) as PermissionsResponse,
      {
        enabled: true,
        staleTime: 1000,
      },
    )
  }

  savePermissions = async (data: SavePermissionsRequest): Promise<[] | ErrorDetail> => {
    try {
      const formData = new FormData();
      formData.append('district_id', `${data.districtId}`);
      formData.append('school_id', `${data.schoolId ?? 0}`);
      formData.append('program_id', `${data.programId}`);
      formData.append('permission_id', `${data.permissionId}`);
      formData.append('toggle', `${data.toggle ? 1 : 0}`);

      return await this.getInstance()
        .post('/api/v1/levelset/schedule/permissions/save', formData) as [];
    } catch (err) {
      return new ErrorDetail(err);
    }
  }

  getResetEligibility = ({
    userId, classId, assessmentId, assessmentDateId, languageId, timeTaken, isEnabled,
  }: GetResetEligibilityRequest): UseQueryResult<GetResetEligibilityResponse, unknown> => {
    const formData = new FormData();
    formData.append('user_id', `${userId}`);
    formData.append('class_id', `${classId}`);
    formData.append('assessment_id', `${assessmentId}`);
    formData.append('assessment_date_id', `${assessmentDateId}`);
    formData.append('language_id', `${languageId}`);
    formData.append('time_taken', `${timeTaken}`);
    return useQuery(
      ['resetEligibility', userId, classId, assessmentId, assessmentDateId, languageId, timeTaken],
      async (): Promise<GetResetEligibilityResponse> => await this.getInstance().post(
        '/api/v1/levelset/reset/eligible', formData,
      ) as GetResetEligibilityResponse,
      {
        enabled: isEnabled,
        refetchOnWindowFocus: true,
        retry: 2,
      },
    )
  }

  resetLevelSetTest = async (data: ResetLevelSetTestRequest): Promise<string[]|ErrorDetail> => {
    try {
      const formData = new FormData();
      formData.append('user_id', `${data.userId}`);
      formData.append('assessment_id', `${data.assessmentId}`);
      formData.append('assessment_date_id', `${data.assessmentDateId}`);
      formData.append('language_id', `${data.languageId}`);
      return await this.getInstance()
        .post('/api/v1/levelset/reset/reset', formData) as string[];
    } catch (err) {
      return new ErrorDetail(err);
    }
  }

  getDateRange = (districtId: number, schoolId:number, isEnabled: boolean): UseQueryResult<DateRangeResponse, unknown> => useQuery(
    ['dateRange', districtId],
    async (): Promise<DateRangeResponse> => await this.getInstance().get(
      `api/v1/levelset/schedule/date-range/district/${districtId}/?school_id=${schoolId}`,
    ) as DateRangeResponse,
    {
      enabled: isEnabled,
      staleTime: 5000,
    },
  )

  getLocatorTestLevels = ():UseQueryResult<LocatorTestLevelsResponse[], unknown > => useQuery(
    'locatorTestLevels',
    async (): Promise<unknown> => this.notImplemented(),
  )

  getUserLocatorTestLevel = ():UseQueryResult<UserLocatorTestLevelResponse, unknown > => useQuery(
    'getUserLocatorTestLevel',
    async (): Promise<unknown> => this.notImplemented(),
  )

  saveUserLocatorTestLevel = async ():Promise<UserLocatorTestLevelResponse | ErrorDetail > => {
    const res = await this.notImplemented();
    return new ErrorDetail(res);
  }

  // eslint-disable-next-line class-methods-use-this
  notImplemented = (): Promise<unknown> => Promise.resolve(new Error('Not implemented'));
}
