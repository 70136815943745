import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import User from './interfaces/User';
import Client from './enum/Client';

export type LevelSetSchedulerAppFn = (selector: string, user: User, clientType:Client) => void;

const LevelSetSchedulerApp = async (selector: string, user: User, clientType: Client) => {
  const levelSetContainer = document.getElementById(selector);
  if (!levelSetContainer) {
    // eslint-disable-next-line no-console
    console.error(
      'App Unable to mount LevelSet application to selector: ',
      selector,
    );
    return;
  }
  ReactDOM.render(
    <React.StrictMode>
      <App user={user} clientType={clientType} />
    </React.StrictMode>,
    document.getElementById(selector),
  );
};

export default LevelSetSchedulerApp;
