import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import FormattedMessageRollover from '../lang/FormattedMessage';
import { useAppContext } from '../context/AppContext';
import LanguageEnum from '../enum/Language'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
      '&:focus': {
        outline: '2px solid currentColor',
        outlineOffset: '-2px',
      },
    },
  };
}

export default function BasicTabs() {
  const { setSelectedLanguageId, languageId } = useAppContext();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        setSelectedLanguageId(LanguageEnum.English);
        break;
      case 1:
        setSelectedLanguageId(LanguageEnum.Spanish);
        break;
      default:
        setSelectedLanguageId(LanguageEnum.English);
    }
  };

  const getTabIndex = (val: number, index: number) => {
    const props = { disableRipple: true, tabIndex: val === index ? 0 : -1 };
    return props
  };

  useEffect(() => {
    if (languageId === '2') {
      setValue(1);
    }
  });
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '0' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab {...getTabIndex(value, 0)} label={<FormattedMessageRollover id="english" defaultMessage="English" />} {...a11yProps(0)} />
          <Tab {...getTabIndex(value, 1)} label={<FormattedMessageRollover id="spanish" defaultMessage="Spanish" />} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} />
      <TabPanel value={value} index={1} />
    </Box>
  );
}
