import React, { ReactElement } from 'react';
import { Tooltip } from '@mui/material';

interface PropsToolTip {
  title: string | ReactElement;
  children: ReactElement;
  disabled?: boolean;
  tooltipPlacement?: 'left' | 'right' | 'bottom' | 'top';
}

export default function AccessibleTooltip({
  title,
  children,
  tooltipPlacement = 'bottom',
  disabled,
  ...rest
}: PropsToolTip) {
  const [open, setOpen] = React.useState<boolean>(false);
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && open) {
      event.preventDefault();
      setOpen(false)
    }
  };

  React.useEffect(() => {
    document.body.setAttribute('oneOrMoreTooltip', `${open}`);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  React.useEffect(() => {
    if (disabled && open) {
      setOpen(false);
    }
  }, [disabled, open]);
  return (
    <Tooltip
      title={title}
      placement={tooltipPlacement}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}
