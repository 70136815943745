/* eslint-disable max-len */
import { useQuery, UseQueryResult } from 'react-query';
import HttpClient, { HttpClientConfig } from './HttpClient';
import District from '../../interfaces/District';
import PermissionsResponse from '../../interfaces/api/Permissions/PermissionsResponse';
import config from '../../appConfig';
import { ClassAPIData, LevelsetAPIBase, StudentAPIData } from '../../interfaces/api';
import School from '../../interfaces/School';
import Class from '../../interfaces/Class';
import Student from '../../interfaces/Student';
import LevelSetSchedule from '../../interfaces/LevelSetSchedule';
import PostScheduleObject from '../../interfaces/PostScheduleObject';
import ErrorDetail from '../../util/ErrorDetail';
import { convertToFormData } from '../../util/shared';
import PermissionsRequest from '../../interfaces/api/Permissions/PermissionsRequest';
import SavePermissionsRequest from '../../interfaces/api/Permissions/SavePermissionsRequest';
import DateRangeResponse from '../../interfaces/api/DateRangeResponse';
import GetResetEligibilityRequest from '../../interfaces/api/reset/GetResetEligibilityRequest';
import GetResetEligibilityResponse from '../../interfaces/api/reset/GetResetEligibilityResponse';
import ResetLevelSetTestRequest from '../../interfaces/api/reset/ResetLevelSetTestRequest';
import LevelsetHost from './LevelsetHost';
import KBAuth from '../../interfaces/api/KBAuth';
import LocatorTestLevelsRequest from '../../interfaces/api/LocatorTestLevelsRequest';
import LocatorTestLevelsResponse from '../../interfaces/api/LocatorTestLevelsResponse';
import GetUserLocatorTestLevelRequest from '../../interfaces/api/GetUserLocatorTestLevelRequest';
import UserLocatorTestLevelResponse from '../../interfaces/api/UserLocatorTestLevelResponse';
import SaveUserLocatorTestLevelRequest from '../../interfaces/api/SaveUserLocatorTestLevelRequest';

export default class KidbizHost extends HttpClient {
  private static classInstance?: KidbizHost;

  private tokenExpirationTime: number

  private levelsetInstance: LevelsetHost;

  private accessToken:string

  private userId: number

  private constructor(userId: number) {
    const httpConfig: HttpClientConfig = {
      baseURL: config.KIDBIZ_HOST || 'http://localhost',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json', 'Cache-Control': 'max-age=0' },
    }
    super(httpConfig);
    this.tokenExpirationTime = Date.now() - 1000;
    this.accessToken = '';
    this.userId = userId;
    this.levelsetInstance = LevelsetHost.getInstance();
    this.initializeRequestInterceptor();
  }

  public static getHttpClient(userId: number) {
    if (!this.classInstance) {
      this.classInstance = new KidbizHost(userId);
    }
    return this.classInstance;
  }

  private initializeRequestInterceptor() {
    this.getInstance().interceptors.request.use(
      async (request) => {
        if (!request.headers) {
          return request;
        }
        if (request.url === '/levelset/access-token') {
          return request;
        }
        try {
          const token = await this.getAccessToken();
          request.headers.Authorization = `Bearer ${token}`;
        } catch (err) {
          return Promise.reject(err)
        }
        return request;
      },
      (error) => Promise.reject(error),
    )
  }

  getDistrictData = (isEnabled: boolean, programId: string): UseQueryResult<District[]> => useQuery(
    ['districts', programId],
    async (): Promise<Array<District>> => await this.getInstance().get(`/api/v1/districts/program/${programId}/`) as District[],
    {
      enabled: isEnabled,
      staleTime: 20000,
      refetchOnWindowFocus: false,
    },
  )

  getSchoolData = ({
    districtId, programId, languageId, isEnabled, summer, schoolYearEnd,
  }: LevelsetAPIBase): UseQueryResult<School[], unknown> => useQuery(
    ['schools', districtId, programId, languageId, summer, schoolYearEnd],
    async (): Promise<Array<School>> => await this.getInstance().get(
      // eslint-disable-next-line max-len
      `api/v1/levelset/schedule/district/${districtId}/program/${programId}/language/${languageId}/schools?summer=${summer}&school_year_end=${schoolYearEnd}`,
    ) as School[],
    {
      enabled: isEnabled,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    },
  )

  getClassData = ({
    districtId, schoolId, programId, languageId, summer, isEnabled, schoolYearEnd,
  }: ClassAPIData): UseQueryResult<Class[], unknown> => useQuery(
    ['classes', schoolId, programId, languageId, summer, schoolYearEnd],
    async (): Promise<Array<Class>> => await this.getInstance().get(
      // eslint-disable-next-line max-len
      `/api/v1/levelset/schedule/district/${districtId}/school/${schoolId}/program/${programId}/language/${languageId}/classes?summer=${summer}&school_year_end=${schoolYearEnd}`,
    ) as Class[],
    {
      enabled: isEnabled,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    },
  )

  getLevelsetSchedule = ({
    districtId, programId, languageId, isEnabled, summer, schoolYearEnd,
  }: LevelsetAPIBase, schoolId = 0, classId = 0, userId = 0): UseQueryResult<LevelSetSchedule[], unknown> => {
    const bodyFormData = new FormData();
    bodyFormData.append('district_id', districtId ? `${districtId}` : '0');
    bodyFormData.append('school_id', `${schoolId}`);
    bodyFormData.append('class_id', `${classId}`);
    bodyFormData.append('user_id', `${userId}`);
    bodyFormData.append('program_id', programId);
    bodyFormData.append('language_id', languageId);
    bodyFormData.append('summer', summer);
    bodyFormData.append('school_year_end', `${schoolYearEnd}`)
    return useQuery(
      ['districtSchedule', districtId, programId, languageId, summer, schoolId, classId, userId, schoolYearEnd],
      async (): Promise<Array<LevelSetSchedule>> => await this.getInstance().post(
        'api/v1/levelset/schedule', bodyFormData,
      ) as LevelSetSchedule[],
      {
        enabled: isEnabled && districtId !== null && districtId !== undefined,
        staleTime: 500,
        refetchOnWindowFocus: true,
      },
    );
  }

  getSchoolAdminData = ({
    districtId, programId, languageId, isEnabled, summer, schoolYearEnd,
  }: LevelsetAPIBase, userId: number): UseQueryResult<School[], unknown> => useQuery(
    ['schools', userId, districtId, programId, languageId, summer, schoolYearEnd],
    async (): Promise<Array<School>> => await this.getInstance().get(
      `api/v1/levelset/schedule/district/${districtId}/user/${userId}/program/${programId}/language/${languageId}/schools?summer=${summer}&school_year_end=${schoolYearEnd}`,
    ) as School[],
    {
      enabled: isEnabled,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    },
  )

  getStudentData = ({
    districtId, schoolId, programId, languageId, classId, summer, isEnabled, schoolYearEnd,
  }: StudentAPIData): UseQueryResult<Student[], unknown> => useQuery(
    ['students', districtId, classId, languageId, summer, schoolYearEnd],
    async (): Promise<Array<Student>> => await
    // eslint-disable-next-line max-len
    this.getInstance().get(`/api/v1/levelset/schedule/district/${districtId}/school/${schoolId}/class/${classId}/program/${programId}/language/${languageId}/students?summer=${summer}&school_year_end=${schoolYearEnd}`) as Student[],
    {
      enabled: isEnabled,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    },
  )

  getTeacherAdminData = ({
    districtId, programId, languageId, isEnabled, summer, schoolYearEnd,
  }: LevelsetAPIBase, userId: number, schoolId: number): UseQueryResult<Class[], unknown> => useQuery(
    ['teacher', userId, districtId, programId, languageId, summer, schoolYearEnd],
    async (): Promise<Array<Class>> => await this.getInstance().get(
      // eslint-disable-next-line max-len
      `api/v1/levelset/schedule/teacher/${userId}/district/${districtId}/school/${schoolId}/program/${programId}/language/${languageId}/classes?summer=${summer}&school_year_end=${schoolYearEnd}`,
    ) as Class[],
    {
      enabled: isEnabled,
      staleTime: 1000,
      refetchOnWindowFocus: true,
    },
  )

  postLevelSetSchedule = async (obj: PostScheduleObject): Promise<string[]|ErrorDetail> => {
    try {
      const d = convertToFormData<PostScheduleObject>(obj, new FormData());
      return await this.getInstance()
        .post('/api/v1/levelset/schedule/save', d) as string[];
    } catch (err) {
      return new ErrorDetail(err);
    }
  }

  getPermissions = ({
    districtId, programId, schoolId, isEnabled,
  }: PermissionsRequest): UseQueryResult<PermissionsResponse, unknown> => {
    const formData = new FormData();
    formData.append('district_id', `${districtId}`);
    formData.append('school_id', `${schoolId ?? 0}`);
    formData.append('program_id', `${programId}`);
    formData.append('api_version', '2');
    return useQuery(
      ['permissions', districtId, programId, schoolId],
      async (): Promise<PermissionsResponse> => await this.getInstance().post(
        '/api/v1/levelset/schedule/permissions', formData,
      ) as PermissionsResponse,
      {
        enabled: isEnabled,
        refetchOnWindowFocus: true,
        staleTime: 500,
        refetchInterval: 10000,
        retry: 2,
      },
    )
  }

  savePermissions = async (data: SavePermissionsRequest): Promise<[] | ErrorDetail> => {
    try {
      const formData = new FormData();
      formData.append('district_id', `${data.districtId}`);
      formData.append('school_id', `${data.schoolId ?? 0}`);
      formData.append('program_id', `${data.programId}`);
      formData.append('permission_id', `${data.permissionId}`);
      formData.append('toggle', `${data.toggle ? 1 : 0}`);

      return await this.getInstance()
        .post('/api/v1/levelset/schedule/permission/save', formData) as [];
    } catch (err) {
      return new ErrorDetail(err);
    }
  }

  getResetEligibility = ({
    userId, classId, assessmentId, assessmentDateId, languageId, timeTaken, isEnabled,
  }: GetResetEligibilityRequest): UseQueryResult<GetResetEligibilityResponse, unknown> => {
    const formData = new FormData();
    formData.append('user_id', `${userId}`);
    formData.append('class_id', `${classId}`);
    formData.append('assessment_id', `${assessmentId}`);
    formData.append('assessment_date_id', `${assessmentDateId}`);
    formData.append('language_id', `${languageId}`);
    formData.append('time_taken', `${timeTaken}`);
    return useQuery(
      ['resetEligibility', userId, classId, assessmentId, assessmentDateId, languageId, timeTaken],
      async (): Promise<GetResetEligibilityResponse> => await this.getInstance().post(
        '/api/v1/levelset/reset/eligible', formData,
      ) as GetResetEligibilityResponse,
      {
        enabled: isEnabled,
        refetchOnWindowFocus: true,
        retry: 2,
        cacheTime: 0,
      },
    )
  }

  resetLevelSetTest = async (data: ResetLevelSetTestRequest): Promise<string[]|ErrorDetail> => {
    try {
      const formData = new FormData();
      formData.append('user_id', `${data.userId}`);
      formData.append('assessment_id', `${data.assessmentId}`);
      formData.append('assessment_date_id', `${data.assessmentDateId}`);
      formData.append('language_id', `${data.languageId}`);
      return await this.getInstance()
        .post('/api/v1/levelset/reset/reset', formData) as string[];
    } catch (err) {
      return new ErrorDetail(err);
    }
  }

  getDateRange = (districtId: number, schoolId:number, isEnabled: boolean, schoolYearEnd: number): UseQueryResult<DateRangeResponse, unknown> => useQuery(
    ['dateRange', districtId, schoolYearEnd],
    async (): Promise<DateRangeResponse> => await this.getInstance().get(
      `api/v1/levelset/schedule/date-range/district/${districtId}/?school_id=${schoolId}&school_year_end=${schoolYearEnd}`,
    ) as DateRangeResponse,
    {
      enabled: isEnabled,
      staleTime: 5000,
      refetchOnWindowFocus: true,
    },
  )

  getLocatorTestLevels = ({
    productId, languageId, academicYearId, isEnabled,
  }
  : LocatorTestLevelsRequest):UseQueryResult<LocatorTestLevelsResponse[], unknown > => useQuery(
    ['locatorTestLevels', productId, languageId, academicYearId],
    async (): Promise<LocatorTestLevelsResponse[]> => {
      const token = await this.getAccessToken();
      return this.levelsetInstance.getLocatorTestLevels({
        productId, languageId, academicYearId, isEnabled,
      }, token);
    },
    {
      enabled: isEnabled,
      staleTime: 1000,
    },
  )

  getUserLocatorTestLevel = ({
    userId, productId, academicYearId, languageId, grade, isEnabled,
  }
  : GetUserLocatorTestLevelRequest):UseQueryResult<UserLocatorTestLevelResponse, unknown > => useQuery(
    ['getUserLocatorTestLevel', userId, productId, languageId],
    async (): Promise<UserLocatorTestLevelResponse> => {
      const token = await this.getAccessToken();
      return this.levelsetInstance.getUserLocatorTestLevel({
        userId, productId, academicYearId, languageId, grade, isEnabled,
      }, token);
    },
    {
      enabled: isEnabled,
      staleTime: 1000,
    },
  )

  saveUserLocatorTestLevel = async (data: SaveUserLocatorTestLevelRequest):Promise<UserLocatorTestLevelResponse|ErrorDetail> => {
    try {
      const token = await this.getAccessToken();
      return await this.levelsetInstance.saveUserLocatorTestLevel(data, token);
    } catch (err) {
      return new ErrorDetail(err);
    }
  }

  getAccessToken = async () : Promise<string> => {
    if (this.tokenExpirationTime > Date.now() && this.accessToken !== '') {
      return Promise.resolve(this.accessToken);
    }
    try {
      const res = await this.getInstance().get('/levelset/access-token') as KBAuth;
      this.accessToken = res.token;
      const minutesInMilliseconds = 5 * 60 * 1000;
      this.tokenExpirationTime = Date.now() + minutesInMilliseconds;
      return res.token;
    } catch (err) {
      return Promise.reject(err)
    }
  }
}
