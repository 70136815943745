import TableRow from '@mui/material/TableRow';
import React from 'react';
import { TableDataPaginationSpacer } from '../../styled/styles';

export default () => (
  <>
    <TableRow component="div">
      <TableDataPaginationSpacer>
        <span style={{ visibility: 'hidden' }}>Pagination Spacer</span>
      </TableDataPaginationSpacer>
    </TableRow>
    <TableRow component="div">
      <TableDataPaginationSpacer>
        <span style={{ visibility: 'hidden' }}>Pagination Spacer</span>
      </TableDataPaginationSpacer>
    </TableRow>
  </>
);
