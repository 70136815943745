import * as React from 'react';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import styled from '@emotion/styled';
import { useMutation, useQueryClient } from 'react-query';
import SummerSwitch from './Switch';
import { usePermissions } from './shared/NavBar';
import FormattedMessageRollover from '../lang/FormattedMessage';
import Roles from '../enum/Roles';
import { useAppContext } from '../context/AppContext';
import AutoCompleteComponent from './shared/AutoComplete';
import District from '../interfaces/District';
import SavePermissionsRequest from '../interfaces/api/Permissions/SavePermissionsRequest';
import PermissionEnum from '../enum/Permission';
import ErrorDetail from '../util/ErrorDetail';
import { PermissionCreatedBy } from '../interfaces/api/Permissions/PermissionsResponse';
import ProgramEnum from '../enum/Program';

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.palette.error.main};
  cursor: pointer;
  padding: 1rem;
`

const PermissionInfoDiv = styled.div`
  font-style: italic;
  margin-left: 3rem;
`
export default () => {
  const { permissionsData, isPermissionsLoadingError, isPermissionsLoading } = usePermissions();
  const {
    districtId, programId, setSelectedDistrictId, user: {
      role, schoolId, isEditable, summerAccess,
    }, httpClient: { getDistrictData, savePermissions },
  } = useAppContext();

  const isEnabled = !!districtId && !isPermissionsLoading && !isPermissionsLoadingError && permissionsData !== undefined;
  const queryClient = useQueryClient();
  const { data: districts } = getDistrictData((role === Roles.KBA && !districtId), programId);
  const [schoolSwitch, setSchoolSwitch] = React.useState(false);
  const [teachersSwitch, setTeachersSwitch] = React.useState(false);
  const [saResetSwitch, setSaResetSwitch] = React.useState(false);
  const [teachersResetSwitch, setTeachersResetSwitch] = React.useState(false);
  const [error, setError] = React.useState('');
  const [interimIISwitch, setInterimIISwitch] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [chosenDistrict, setChosenDistrict] = React.useState<District|null>(null);

  const handleDistrictIdChange = (value: React.SetStateAction<District | null>): void => {
    setChosenDistrict(value);
    if (value === null) {
      setSelectedDistrictId(0);
    } else {
      const district = value as District;
      setSelectedDistrictId(district.district_id);
    }
  }

  const mutation = useMutation({
    mutationFn: savePermissions,
    onSuccess: () => {
      queryClient.invalidateQueries(['permissions', districtId, programId, schoolId]);
    },
    onError: (muError) => {
      if (muError instanceof ErrorDetail) {
        setError(ErrorDetail.getErrorKey(muError.errorResponse.errorCode))
      }
    },
  })
  const shouldLoadSummerSwitch = () => {
    if (!permissionsData) {
      return false;
    }
    if (programId === ProgramEnum.LanguageArt) {
      return summerAccess;
    }
    return false;
  }

  useEffect(() => {
    if (districtId && districts) {
      const res = districts.find((district) => district.district_id === districtId);
      if (res) {
        setChosenDistrict(res);
      }
    }
    if (!permissionsData) {
      return;
    }
    setSchoolSwitch(!permissionsData.school_admin_disabled.value);
    setTeachersSwitch(!permissionsData.teacher_disabled.value);
    setSaResetSwitch(!permissionsData.school_admin_reset_disabled.value);
    setInterimIISwitch(permissionsData.interim_II_test_enabled.value);
    setTeachersResetSwitch(!permissionsData.teacher_reset_disabled.value);
  }, [permissionsData, chosenDistrict])

  const onClickSwitchToggle = async (permissionId:PermissionEnum, newValue: boolean, setState: React.Dispatch<boolean>) => {
    setIsLoading(true);
    const postObject: SavePermissionsRequest = {
      districtId,
      schoolId: role === Roles.SCHOOL_ADMIN ? schoolId : 0,
      programId,
      toggle: newValue,
      permissionId,
    }
    await mutation.mutate(postObject);
    if (mutation.isError) {
      return false;
    }
    setIsLoading(false);
    setError('');
    setState(newValue);
    return true;
  }

  const getPermissionEnabledMessage = (isPermEnabled: boolean) => (isPermEnabled ? (
    <PermissionInfoDiv>
      <FormattedMessageRollover id="enabled" />
    </PermissionInfoDiv>
  ) : null);

  const getPermissionDisabledMessage = (isDisabled:boolean, createdBy: PermissionCreatedBy, checkSchoolAdmin = false) => {
    if (isDisabled && createdBy === PermissionCreatedBy.DA) {
      return (
        <PermissionInfoDiv>
          <FormattedMessageRollover id="permissionDisabledByDA" />
        </PermissionInfoDiv>
      );
    }
    if (checkSchoolAdmin && isDisabled && createdBy === PermissionCreatedBy.SA) {
      return (
        <PermissionInfoDiv>
          <FormattedMessageRollover id="permissionDisabledBySA" />
        </PermissionInfoDiv>
      );
    }
    return null;
  }

  const shouldDisablePermissionUpdates = (isDisabled:boolean, createdBy: PermissionCreatedBy) => isDisabled && createdBy === PermissionCreatedBy.DA

  const getSchoolAdminScheduleSettings = (node?: React.ReactNode|null, disableSave = false) => (
    <Grid item xs={12} sm={6} md={4} lg={4} minWidth={370}>
      <FormControlLabel
        disabled={!isEnabled || isLoading || !isEditable || disableSave}
        control={(
          <Switch
            checked={schoolSwitch}
            onChange={() => onClickSwitchToggle(PermissionEnum.SchoolAdminDisabled, !schoolSwitch, setSchoolSwitch)}
          />
        )}
        label={(
          <FormattedMessageRollover
            id="allowSchoolAdminAccess"
            defaultMessage="Allow school administrators to schedule"
            dataTestId="schoolAdminAccessLabel"
          />
        )}
      />
      {getPermissionEnabledMessage(schoolSwitch)}
      {node ?? null}
    </Grid>
  )

  const getTeacherAdminScheduleSettings = (node?: React.ReactNode|null, disableSave = false) => (
    <Grid item xs={12} sm={6} md={4} lg={4} minWidth={370}>
      <FormControlLabel
        disabled={!isEnabled || isLoading || !isEditable || disableSave}
        control={(
          <Switch checked={teachersSwitch} onChange={() => onClickSwitchToggle(PermissionEnum.TeacherDisabled, !teachersSwitch, setTeachersSwitch)} />
        )}
        label={(
          <FormattedMessageRollover
            id="allowTeacherAccess"
            defaultMessage="Allow teachers to schedule"
            dataTestId="teacherAdminAccessLabel"
          />
        )}
      />
      {getPermissionEnabledMessage(teachersSwitch)}
      {node ?? null}
    </Grid>
  )

  const getSchoolAdminResetSettings = (node?: React.ReactNode | null, disableSave = false) => (
    <Grid item xs={12} sm={6} md={4} lg={4} minWidth={370}>
      <FormControlLabel
        disabled={!isEnabled || isLoading || !isEditable || disableSave}
        control={(
          <Switch
            checked={saResetSwitch}
            onChange={() => onClickSwitchToggle(PermissionEnum.SchoolAdminResetDisabled, !saResetSwitch, setSaResetSwitch)}
          />
        )}
        label={(
          <FormattedMessageRollover
            id="allowSchoolAdminReset"
            defaultMessage="Allow schools administrators to reset"
            dataTestId="schoolAdminResetLabel"
          />
        )}
      />
      {getPermissionEnabledMessage(saResetSwitch)}
      {node ?? null}
    </Grid>
  )

  const getTeacherAdminResetSettings = (node?: React.ReactNode|null, disableSave = false) => (
    <Grid item xs={12} sm={6} md={4} lg={4} minWidth={370}>
      <FormControlLabel
        disabled={!isEnabled || isLoading || !isEditable || disableSave}
        control={(
          <Switch
            checked={teachersResetSwitch}
            onChange={() => onClickSwitchToggle(PermissionEnum.TeacherResetDisabled, !teachersResetSwitch, setTeachersResetSwitch)}
          />
        )}
        label={(
          <FormattedMessageRollover
            id="allowTeacherReset"
            defaultMessage="Allow teachers to reset"
            dataTestId="teacherAdminResetLabel"
          />
        )}
      />
      {getPermissionEnabledMessage(teachersResetSwitch)}
      {node ?? null}
    </Grid>
  )

  const getInterimIILevelsetSettings = (disableSave = false) => (
    <>
      <Grid item xs={12}>
        <h2>
          <FormattedMessageRollover
            id="globalLevelSetPermissions"
            defaultMessage="Global LevelSet permissions"
            values={{ editable: '' }}
          />
        </h2>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} minWidth={370}>
        <FormControlLabel
          disabled={!isEnabled || isLoading || !isEditable || disableSave}
          control={<Switch checked={interimIISwitch} />}
          onChange={() => onClickSwitchToggle(PermissionEnum.InterimIITestDisabled, !interimIISwitch, setInterimIISwitch)}
          label={(
            <FormattedMessageRollover
              id="allowInterimIISchedule"
              defaultMessage="Allow Interim II Levelset to schedule"
              dataTestId="allowInterimIILsScheduleAndDeliver"
            />
          )}
        />
        {getPermissionEnabledMessage(interimIISwitch)}
      </Grid>
    </>
  );
  const loadDataForKBAorDA = (requireDistrictAutoComplete = false) => (
    <>
      {requireDistrictAutoComplete && (
        <Grid item xs={12}>
          {districts && (
            <AutoCompleteComponent
              id="district_autocomplete"
              list={districts}
              chosenItem={chosenDistrict}
              label="chooseDistrict"
              handleIdChange={handleDistrictIdChange}
            />
          )}
        </Grid>
      )}

      <Grid item xs={12}>
        <h2>
          <FormattedMessageRollover id="schoolAdminPermissions" defaultMessage="School admins LevelSet permissions ( Editable )" />
        </h2>
      </Grid>
      {getSchoolAdminScheduleSettings()}
      {getSchoolAdminResetSettings()}
      <Grid item xs={12}>
        <h2>
          <FormattedMessageRollover id="teachersLevelSetPermissions" defaultMessage="Teachers LevelSet permissions ( Editable )" />
        </h2>
      </Grid>
      {getTeacherAdminScheduleSettings()}
      {getTeacherAdminResetSettings()}
      {getInterimIILevelsetSettings()}
    </>
  );

  const loadDataForSchoolAdmin = () => {
    if (!permissionsData) {
      return null;
    }
    return (
      <>
        <Grid item xs={12} md={12}>
          <h2>
            <FormattedMessageRollover id="myLevelSetPermissions" defaultMessage="My LevelSet permissions" />
          </h2>
        </Grid>
        {getSchoolAdminScheduleSettings(
          getPermissionDisabledMessage(permissionsData.school_admin_disabled.value, permissionsData.school_admin_disabled.created_by),
          true,
        )}
        {getSchoolAdminResetSettings(
          getPermissionDisabledMessage(permissionsData.school_admin_reset_disabled.value, permissionsData.school_admin_reset_disabled.created_by),
          true,
        )}
        <Grid item xs={12} md={12}>
          <h2>
            <FormattedMessageRollover id="teachersLevelSetPermissions" defaultMessage="Teachers Levelset permissions" />
          </h2>
        </Grid>
        {getTeacherAdminScheduleSettings(
          getPermissionDisabledMessage(permissionsData.teacher_disabled.value, permissionsData.teacher_disabled.created_by),
          shouldDisablePermissionUpdates(permissionsData.teacher_disabled.value, permissionsData.teacher_disabled.created_by),
        )}
        {getTeacherAdminResetSettings(
          getPermissionDisabledMessage(permissionsData.teacher_reset_disabled.value, permissionsData.teacher_reset_disabled.created_by),
          shouldDisablePermissionUpdates(permissionsData.teacher_reset_disabled.value, permissionsData.teacher_reset_disabled.created_by),
        )}
      </>
    )
  }

  const loadDataForTeacherAdmin = () => {
    if (!permissionsData) {
      return null;
    }
    return (
      <>
        <Grid item xs={12} md={12}>
          <h2>
            <FormattedMessageRollover id="myLevelSetPermissions" defaultMessage="My LevelSet permissions" />
          </h2>
        </Grid>
        {getTeacherAdminScheduleSettings(getPermissionDisabledMessage(
          permissionsData.teacher_disabled.value,
          permissionsData.teacher_disabled.created_by,
          true,
        ), true)}
        {getTeacherAdminResetSettings(getPermissionDisabledMessage(
          permissionsData.teacher_reset_disabled.value,
          permissionsData.teacher_reset_disabled.created_by,
          true,
        ), true)}
        {getInterimIILevelsetSettings(
          true,
        )}
      </>
    )
  }

  const render = () => {
    switch (role) {
      case Roles.KBA:
        return loadDataForKBAorDA(true);
      case Roles.DISTRICT_ADMIN:
        return loadDataForKBAorDA();
      case Roles.SCHOOL_ADMIN:
        return loadDataForSchoolAdmin();
      case Roles.TEACHER_ADMIN:
        return loadDataForTeacherAdmin();
      default:
        return null;
    }
  }

  return (
    <Grid container spacing={2} textAlign="start" sx={{ margin: '0.5rem', padding: '0.5rem' }}>
      {render()}
      {shouldLoadSummerSwitch() && permissionsData !== undefined && (
        <>
          <SummerSwitch onSwitchToggle={onClickSwitchToggle} isLoading={isLoading} permissionData={permissionsData} />
        </>
      )}
      {error && (
        <Grid xs={12} sx={{ marginLeft: '3rem' }}>
          <ErrorDiv>{error}</ErrorDiv>
        </Grid>
      )}
      {isPermissionsLoadingError && (
        <ErrorDiv>
          <FormattedMessageRollover id="permissionsLoadingError" defaultMessage="Unable to load Permissions data" />
        </ErrorDiv>
      )}
    </Grid>
  )
}
