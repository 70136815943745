// eslint-disable-next-line no-shadow
export enum PermissionCreatedBy{
  SA ='SA',
  NA ='NA',
  DA ='DA'
}

interface PermissionObject {
  value: boolean,
  created_by: PermissionCreatedBy
}

export default interface PermissionsResponse {
  school_id: PermissionObject
  school_admin_disabled: PermissionObject,
  teacher_disabled: PermissionObject,
  school_admin_reset_disabled: PermissionObject,
  interim_II_test_enabled: PermissionObject,
  teacher_reset_disabled: PermissionObject,
  levelset_scheduler_include_summer: PermissionObject,
}
