import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import React, { ChangeEvent } from 'react';
import styled from '@emotion/styled';

const SearchDiv = styled.div`
  margin-left: 1rem;
`
const SearchTableCell = styled(TableCell)`
  border: none;
  padding: 0;
  margin: 0;
  width: 345px;
`

interface SearchTextFieldProps {
  id: string,
  labelId: string,
  handleSearch: (event: ChangeEvent<HTMLInputElement| HTMLTextAreaElement>) => void;
  helperDataText?: string,
}
export default ({
  handleSearch, id, labelId, helperDataText,
}: SearchTextFieldProps) => (
  <SearchTableCell>
    <SearchDiv>
      <TextField
        id={id}
        label={<FormattedMessage id={labelId} />}
        data-testid={labelId}
        variant="outlined"
        onChange={(e) => handleSearch(e)}
        sx={{
          width: 340, float: 'left', marginTop: '0.5rem', marginBottom: '1.5rem',
        }}
        helperText={helperDataText}
        FormHelperTextProps={{ 'aria-live': 'assertive', 'aria-atomic': 'true', sx: { marginLeft: 0 } }}
        error={!!helperDataText}
      />
    </SearchDiv>
  </SearchTableCell>
)
