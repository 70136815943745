import * as React from 'react';
import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import { format as dateFnsFormat } from 'date-fns';
import { DesktopDatePicker as DatePicker, PickersDay } from '@mui/x-date-pickers';
import { visuallyHidden } from '@mui/utils';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import FormattedMessageRollover from '../../lang/FormattedMessage';
import { useAppContext } from '../../context/AppContext';
import MultiSelectLevel from '../../enum/MultiSelectLevel';
import SaveConfirmationPopup from '../popup/SaveConfirmationPopup';
import PostScheduleObject from '../../interfaces/PostScheduleObject';
import liveAnnounce from '../../util/LiveAnnouncement';
import AccessibleTooltip from '../AccessibleTooltip';
import AlertDialog from '../popup/AlertDialog';

interface StyledButtonProps {
  width?: string;
}

const StyledButton = styled(Button)<StyledButtonProps>`
  width: ${(props) => (props.width ?? '5.75rem')};
`
interface DatePickerProps {
  assessmentScheduleId: number|null,
  scheduleTime: string,
  override: string|null,
  autoSchedule: string|null,
  assessmentId: string,
  assessmentIdLabel: string,
  assessmentDateId: string,
  districtId: number,
  districtName: string,
  schoolId: number,
  schoolName: string,
  classId: number,
  className: string,
  studentId: number,
  studentName: string,
  multiSelectLevel: MultiSelectLevel|string,
  schoolStart: Date|null,
  schoolEnd: Date|null,
}

const localeMap = {
  en: enLocale,
  es: esLocale,
  'en-es': esLocale,
};

function getDateAndLabel(scheduleTime: string) {
  let parsedDate: Date|null = null;
  let parsedLabel = 'Schedule Time';
  let parsedLabelId = 'scheduleTime'
  if (scheduleTime === 'UNSCHEDULED') {
    parsedLabel = 'NOT SCHEDULED';
    parsedLabelId = 'unscheduled'
    return { parsedDate, parsedLabel, parsedLabelId }
  }

  if (!scheduleTime || scheduleTime.length !== 10) {
    return { parsedDate, parsedLabel, parsedLabelId }
  }

  try {
    if (scheduleTime === '0000-00-00') {
      parsedLabel = 'NOT SCHEDULED';
      parsedLabelId = 'unscheduled';
    } else {
      parsedDate = new Date(scheduleTime.replace(/-/g, '/'));
    }
  } catch (err) {
    parsedDate = null;
  }
  return { parsedDate, parsedLabel, parsedLabelId }
}

const getDatePickerLocale = (locale: string) => {
  switch (locale) {
    case 'es':
      return localeMap.es;
    case 'en-es':
      return localeMap.en;
    default:
      return localeMap.en;
  }
}

export default ({
  assessmentScheduleId, scheduleTime, autoSchedule, assessmentId, assessmentDateId, districtId, districtName,
  classId, className, schoolId, schoolName, studentId, studentName, assessmentIdLabel, multiSelectLevel,
  schoolStart, schoolEnd,
}: DatePickerProps) => {
  const intl = useIntl();

  const {
    languageId, programId, disableDatePicker, activeMultiSelectId, multiSelectedRecordsMap, setActiveMultiSelectId, user: { isInternational },
  } = useAppContext();
  const { parsedLabel, parsedDate, parsedLabelId } = getDateAndLabel(scheduleTime);
  const [value, setValue] = React.useState<Date | null>(parsedDate);
  const [open, setOpen] = React.useState(false);
  const [postData, setPostData] = React.useState<PostScheduleObject|null>(null);
  const [activeRecordKey, setActiveRecordKey] = React.useState<string>('');
  const [confirmDialogOpen, setConfirmDialogAlertOpen] = React.useState<boolean|null>(false);

  // eslint-disable-next-line no-nested-ternary
  const getLabel = () => (value !== null
    ? intl.formatMessage({ id: 'scheduleTimeEditText', defaultMessage: 'Schedule Time' })
    : (parsedLabelId ? intl.formatMessage({ id: parsedLabelId, defaultMessage: parsedLabel }) : parsedLabel));
  const newDate = parsedDate ?? new Date();
  const onCloseConfirmationBox = (date: Date|null) => {
    setValue(date)
    setOpen(false);
    setPostData(null);
    multiSelectedRecordsMap.clear();
    setActiveMultiSelectId(MultiSelectLevel.Null);
  }

  const handleAlert = () => {
    setConfirmDialogAlertOpen(true);
  }

  const onDateSave = (newScheduleDate: string) => {
    const data = {
      id: assessmentScheduleId,
      schoolId: schoolId ?? 0,
      schoolName: schoolName ?? '',
      classId: classId ?? 0,
      className: className ?? '',
      studentId: studentId ?? 0,
      studentName: studentName ?? '',
    };

    const obj: PostScheduleObject = {
      assessmentId,
      scheduleTime: newScheduleDate,
      assessmentLabelId: assessmentIdLabel,
      assessmentDateId,
      districtId,
      districtName: districtName || '',
      programId,
      languageId,
      isOverride: 0,
      assessmentSchedule: [data],
    }

    setActiveRecordKey(`scheduler_${districtId}_${data.schoolId}_${data.classId}_${data.studentId}`)
    if (activeMultiSelectId !== multiSelectLevel) {
      setPostData({ ...obj });
      return;
    }

    multiSelectedRecordsMap.forEach((record) => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < record?.assessments?.length; i++) {
        if (record.assessments[i].assessment_id === assessmentId && record.assessments[i].assessment_date_id === assessmentDateId) {
          if (data.id === record.assessments[i].id
            && data.schoolId === record.schoolId
            && data.classId === record.classId
            && data.studentId === record.studentId
          ) {
            break;
          }
          obj.assessmentSchedule.push({
            id: record.assessments[i].id,
            schoolId: record.schoolId,
            schoolName: record.schoolName,
            classId: record.classId,
            className: record.className,
            studentId: record.studentId,
            studentName: record.studentName,
          })
          break;
        }
      }
    })
    setPostData({ ...obj });
  }

  const prefixZero = (v: string): string => (v.length === 1 ? `0${v}` : v)

  const formatDate = (d: Date) => `${d.getFullYear()}`.concat('/', prefixZero(`${d.getMonth() + 1}`), '/', prefixZero(`${d.getDate()}`));

  const getFormatString = () : string => {
    const dayMonthLocales = ['es', 'en-GB'];

    if (isInternational || dayMonthLocales.includes(intl.locale)) {
      return 'dd MMM yyyy';
    }
    return 'MMM dd, yyyy';
  }

  useEffect(() => {
    setValue(parsedDate);
  }, [scheduleTime])

  const focusDialogCloseButton = () => {
    setTimeout(() => {
      const focusElement = document.querySelector('#dialogCloseButton');
      if (focusElement) {
        (focusElement as HTMLElement).focus();
      }
    }, 50);
  };
  return (
    <>
      <div id="selectedDate" style={visuallyHidden} aria-live="assertive" role="status" aria-atomic="true" />
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={getDatePickerLocale(intl.locale)}>
        <DatePicker
          defaultCalendarMonth={new Date(
            schoolStart ? schoolStart.getFullYear() : (new Date().getFullYear()),
            schoolStart ? schoolStart.getMonth() : (new Date().getMonth() + 1),
            schoolStart ? schoolStart.getDay() : (new Date().getDate()),
          )}
          disabled={disableDatePicker}
          data-testid="datePicker"
          label={getLabel()}
          inputFormat={getFormatString()}
          mask=""
          value={value}
          disableHighlightToday={!!value}
          renderDay={(day, _selectedDate, DayComponentProps) => (
            <PickersDay
              {...DayComponentProps}
              aria-selected={
                _selectedDate && _selectedDate[0] && _selectedDate[0].getTime() === day.getTime()
                  ? 'true'
                  : undefined
              }
              aria-current={
                _selectedDate && _selectedDate[0] && _selectedDate[0].getTime() === day.getTime()
                  ? 'date'
                  : undefined
              }
              aria-label={dateFnsFormat(day, 'd MMMM, yyyy')}
              onClick={() => liveAnnounce(dateFnsFormat(day, 'd MMMM, yyyy'), 'selectedDate')}
            />
          )}
          showToolbar
          leftArrowButtonText={intl.formatMessage({ id: 'previousMonth', defaultMessage: 'Previous month' })}
          rightArrowButtonText={intl.formatMessage({ id: 'nextMonth', defaultMessage: 'Next month' })}
          // Todo make sure date ranges work accurately and remove null from date range
          maxDate={schoolEnd ?? new Date(newDate.getFullYear() + 2, newDate.getMonth(), newDate.getDay())}
          minDate={schoolStart ?? new Date(newDate.getFullYear() - 2, newDate.getMonth(), newDate.getDay())}
          open={open}
          PaperProps={{ style: { minHeight: '364px' } }}
          PopperProps={{
            style: { zIndex: 100, display: postData === null ? 'block' : 'none' },
            sx: {
              '& .ls-ui-MuiIconButton-sizeSmall': {
                '&:focus-visible': {
                  outline: '2px solid black',
                  outlineOffset: '-4px',
                },
              },
              '& .Mui-selected:focus': {
                '&:focus': {
                  outline: '2px solid black',
                  outlineOffset: '1px',
                },
              },
            },
          }}
          ToolbarComponent={(props) => {
            const { date } = props;
            return (
              <div
                style={{
                  display: 'flex', bottom: '5px', position: 'absolute', zIndex: 10, width: '100%', justifyContent: 'space-between',
                }}
              >
                <StyledButton
                  disabled={!value}
                  data-testid="datePickerSaveButton"
                  disableRipple
                  onClick={() => {
                    setValue(date);
                    setOpen(false)
                    onDateSave(date ? formatDate(date) : '');
                    focusDialogCloseButton();
                  }}
                >
                  <FormattedMessageRollover id="save" defaultMessage="Save" />
                </StyledButton>
                <StyledButton disableRipple onClick={() => { setValue(parsedDate); setOpen(false) }} data-testid="datePickerDiscardButton">
                  <FormattedMessageRollover id="discard" defaultMessage="Discard" />
                </StyledButton>
                <StyledButton
                  disableRipple
                  width="7.75rem"
                  data-testid="datePickerCancelTestButton"
                  onClick={() => { onDateSave('0000-00-00'); setValue(null); focusDialogCloseButton(); }}
                >
                  <FormattedMessageRollover id="cancelTest" defaultMessage="cancel test" />
                </StyledButton>
              </div>
            )
          }}
          components={{
            LeftArrowButton: (props) => (
              <AccessibleTooltip title={intl.formatMessage({ id: 'previousMonth', defaultMessage: 'Previous month' })}>
                <IconButton {...props} />
              </AccessibleTooltip>
            ),
            RightArrowButton: (props) => (
              <AccessibleTooltip title={intl.formatMessage({ id: 'nextMonth', defaultMessage: 'Next month' })}>
                <IconButton {...props} />
              </AccessibleTooltip>
            ),
          }}
          disableCloseOnSelect
          onChange={(newValue) => {
            setValue(newValue);
          }}
          onOpen={() => setOpen(true)}
          onClose={() => {
            if (postData === null) {
              setValue(parsedDate);
              setOpen(false)
            } else {
              setValue(value);
            }
          }}
          renderInput={(params) => {
            const dateParams = {
              ...params,
              inputProps: {
                ...params.inputProps,
                readOnly: true,
                tabIndex: -1,
              },
            };
            return (
              <TextField
                {...dateParams}
                sx={{
                  fontWeight: assessmentScheduleId && autoSchedule === 'N' ? 'bold' : 'normal',
                  width: '100%',
                  '& button:focus': {
                    outline: '2px solid black',
                    outlineOffset: '-2px',
                  },
                }}
              />
            )
          }}
        />
      </LocalizationProvider>
      {postData !== null && (
        <SaveConfirmationPopup
          postScheduleObject={postData}
          prevDate={parsedDate}
          currDate={value}
          visible
          onClose={onCloseConfirmationBox}
          dateFormat={getFormatString()}
          activeRecordKey={activeRecordKey}
          handleAlert={handleAlert}
        />
      )}
      {confirmDialogOpen && (
        <AlertDialog
          dialogTitle="scheduleTime"
          dialogText="scheduleTimeSaveSuccess"
          onClose={() => setConfirmDialogAlertOpen(false)}
        />
      )}
    </>
  );
}
